import RestaurantIcon from "@mui/icons-material/Restaurant";
import SettingsIcon from "@mui/icons-material/Settings";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import BarChartIcon from "@mui/icons-material/BarChart";

export const marchantIdDefined = "663baf33dc0be5b59ae65ea0";
export const API_URL = process.env.REACT_APP_API_URL ??  "https://api-affiliate.doliqr.com/";
export const QR_CODE_URL = process.env.QR_CODE_URL ?? "https://go.doliqr.com";

export const pagesList = [
  { name: "Menus", icon: <RestaurantIcon />, link: "/user/menus", id: 0 },
  { name: "Stats", icon: <BarChartIcon />, link: "/user/stats", id: 1 },
  { name: "QR code", icon: <QrCode2Icon />, link: "/user/qrcode", id: 2 },
  { name: "Réglages", icon: <SettingsIcon />, link: "/user/settings", id: 3 },
];
export const currentUser = {
  firstName: "John",
  lastName: "Doe",
  profilePicture: "https://i.pravatar.cc/150?img=68",
};
export const plansList = [
  {
    title: "Pro",
    name: "Forfait Pro",
    price: "39,99",
    billedType: "EUR payé chaque mois",
    avantages: [
      "Menus illimités",
      "Recettes illimités",
      "Utilisateurs illimités",
      "Visites illimités",
      "Assistance 7/7",
    ],
  },
  {
    title: "Débutant",
    name: "Forfait Débutant",
    billedType: "Gratuit à vie",
    free: true,
    price: "Gratuit",
    avantages: ["1 Menu", "20 Recettes", "1 Utilisateur", "500 Visites"],
  },
];

export const plansListAnnual = [
  {
    title: "Pro",
    name: "Forfait Pro",
    price: "29,99",
    billedType: "EUR payé chaque année",
    avantages: [
      "Menus illimités",
      "Recettes illimités",
      "Utilisateurs illimités",
      "Visites illimités",
      "Assistance 7/7",
    ],
  },
  {
    title: "Débutant",
    name: "Forfait Débutant",
    billedType: "Gratuit à vie",
    free: true,
    price: "Gratuit",
    avantages: ["1 Menu", "20 Recettes", "1 Utilisateur", "500 Visites"],
  },
];

export const getActualPlan = (isSubscribed, isAnnual = true) => {
  const list = isAnnual ? plansListAnnual : plansList;
  return list[isSubscribed ? 0 : 1];
};

export const qrCodeDropdownOptions = [
  {
    value: "pdf-16",
    label: "PDF avec 16 petits QR codes",
  },
  {
    value: "pdf-9",
    label: "PDF avec 9 moyens QR codes",
  },
  {
    value: "pdf-4",
    label: "PDF avec 4 larges QR codes",
  },
  {
    value: "png",
    label: "QR code en PNG",
  },
  {
    value: "svg",
    label: "QR code en SVG",
  },
];

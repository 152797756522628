import * as React from "react";
import CustomModal from "../Modal/Modal";
import CreateProduct from "./CreateProduct";

export default function CreateProductModal({
  open,
  handleClose,
  categoryId,
  menuId,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <CreateProduct
        closeModal={handleClose}
        categoryId={categoryId}
        menuId={menuId}
      />
    </CustomModal>
  );
}

import React from "react";
import AddItemInputContainer from "../common/AddItemInputContainer";
import Container from "@mui/material/Container";
import CustomListButton from "../common/CustomListButton";
import CloseIcon from "@mui/icons-material/Close";
import { CategoriesContext } from "../Context/CategoriesContext";
import UploadFolderButton from "../common/UploadFolder/UploadFolderButton";

function EditProduct({
  id,
  actualName,
  actualDescription,
  actualPrice,
  categoryId,
  actualImage,
  actualCustomImage,
  closeModal,
}) {
  const { updateProduct } = React.useContext(CategoriesContext);
  const [name, setName] = React.useState(actualName);
  const [description, setDescription] = React.useState(actualDescription);
  const [price, setPrice] = React.useState(actualPrice);
  const [image, setImage] = React.useState(actualImage);
  const [customImage, setCustomImage] = React.useState(actualCustomImage);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleUpdateProduct = () => {
    if (!name) {
      setErrorMessage("Le nom du plat est obligatoire.");
    } else {
      updateProduct(
        id,
        name,
        description,
        price,
        categoryId,
        customImage,
        image,
        closeModal
      );
    }
  };

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          {name}
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <form>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          {errorMessage && (
            <Container
              sx={{
                fontWeight: "400",
                fontSize: 16,
                color: "var(--unwanted-button-color)",
              }}
            >
              {errorMessage}
            </Container>
          )}
          <AddItemInputContainer
            name={"Nom du produit"}
            //description={"Nom du produit"}
            setter={setName}
            value={name}
            required={true}
          />
          <AddItemInputContainer
            name={"Description du produit"}
            //description={"Description du produit"}
            rows={2}
            setter={setDescription}
            value={description}
          />
          <AddItemInputContainer
            rows={1}
            name={"Prix du produit"}
            //description={"Prix du produit"}
            type={"number"}
            setter={setPrice}
            price={true}
            value={price}
          />
          <UploadFolderButton
            image={image}
            setImage={setImage}
            customImage={customImage}
            setCustomImage={setCustomImage}
          />
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => handleUpdateProduct()}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default EditProduct;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Container } from "@mui/material";
import ProductCard from "./imported/common/ProductCard";
import CustomListButton from "./imported/common/CustomListButton";
import NewCampaignModal from "./NewCampaign/NewCampaignModal";
import Divider from "./imported/common/Divider";
import { API_URL } from "./imported/consts";

function CampaignList() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [showCampaignModal, setShowCampaignModal] = useState(false);

  const fetchAllUserCampaigns = async () => {
    console.log(user.id);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}api/marchants/${user.id}/campaign`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      setCampaigns(response.data);
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const deleteCampaign = async (campaignId) => {
    console.log(user.id);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${API_URL}api/marchants/${campaignId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      fetchAllUserCampaigns();
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  React.useEffect(() => {
    if (user?.id) {
      fetchAllUserCampaigns();
    }
  }, [user]);

  const campaignSettings = (campaignId) => {
    return [{ name: "Supprimer", action: () => deleteCampaign(campaignId) }];
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Liste des campagnes</h1>
        <div style={{ width: "100%", height: 1, margin: "10px 0px 15px 0px" }}>
          <Divider />
        </div>
        <CustomListButton
          text={"Nouvelle campagne"}
          onClick={() => setShowCampaignModal(true)}
          primary={true}
          styles={{
            marginBottom: "20px",
          }}
        />

        {campaigns.map((campaign, index) => (
          <ProductCard
            key={campaign._id}
            name={campaign.campaignName}
            description={campaign.code ? `CODE: ${campaign.code}` : ""}
            image={null}
            customImage={null}
            price={""}
            first={index === 0}
            alone={campaigns.length === 1}
            settings={campaignSettings(campaign._id)}
            onClick={() =>
              navigate(`/campaign/${campaign._id}/${campaign.campaignName}`)
            }
          />
        ))}
      </Container>
      {showCampaignModal && (
        <NewCampaignModal
          open={showCampaignModal}
          handleClose={() => setShowCampaignModal(false)}
        />
      )}
    </>
  );
}

export default CampaignList;

import * as React from "react";
import CustomModal from "../../Modal/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Container, MenuItem, OutlinedInput, Select } from "@mui/material";
import CustomListButton from "../CustomListButton";
import { imagesList } from "../../imagesList";

export default function ChooseImageLibrary({
  open,
  handleClose,
  imageSelected,
  setImage,
  setCustomImage,
  showCategory = true,
  imageListToUse = imagesList,
}) {
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = React.useState(null);

  const handleSelectCategory = (category) => {
    if (category === "all") {
      setSelectedSubcategory(null);
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
      setSelectedSubcategory(null);
    }
  };

  const handleSelectSubcategory = (subcategory) => {
    if (subcategory === "all") {
      setSelectedSubcategory(null);
    } else {
      setSelectedSubcategory(subcategory);
    }
  };

  const groupedImages = React.useMemo(() => {
    return imageListToUse.reduce((acc, image) => {
      image.category.forEach((category) => {
        if (!acc[category]) {
          acc[category] = { images: [], subcategories: {} };
        }
        acc[category].images.push(image);
        if (image.subcategory) {
          image.subcategory.forEach((subcategory) => {
            if (!acc[category].subcategories[subcategory]) {
              acc[category].subcategories[subcategory] = [];
            }
            acc[category].subcategories[subcategory].push(image);
          });
        }
      });
      return acc;
    }, {});
  }, [imageListToUse]);

  const handleImageClick = (image) => {
    setCustomImage(null);
    setImage(image);
    handleClose();
  };

  const handleCustomImageUpload = (event) => {
    setImage(null);
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop();
      const reader = new FileReader();
      reader.onloadend = () => {
        setCustomImage({
          name: file.name,
          image: reader.result,
          extension: fileExtension,
        });
        setImage(null);
      };
      reader.readAsDataURL(file);
    }
    handleClose();
  };

  const handleCustomImageClick = () => {
    document.getElementById("customImageUpload").click();
  };

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <>
        <Container
          style={{
            borderBottom: "1px solid var(--border-color)",
            //paddingBottom: 24,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 20,
            }}
          >
            Choisir une image
          </div>
          <div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
        </Container>
        <form>
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginTop: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                  flex: 2,
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: 18,
                    flex: 1,
                  }}
                >
                  Catégorie
                </div>
                <Select
                  displayEmpty
                  value={selectedCategory ? selectedCategory : "all"}
                  onChange={(e) => handleSelectCategory(e.target.value)}
                  input={<OutlinedInput />}
                  sx={{
                    width: { xs: "100% !important", md: "90% !important" },
                  }}
                  style={{
                    flex: 1,
                    backgroundColor: "var(--default-background-color)",
                    height: 35,
                    fontSize: 15,
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 200,
                      },
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem key={"all"} value={"all"}>
                    Toutes
                  </MenuItem>
                  {Object.keys(groupedImages).map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {selectedCategory && groupedImages[selectedCategory] && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",
                    flex: 2,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: 18,
                      flex: 1,
                    }}
                  >
                    Sous-catégorie
                  </div>
                  <Select
                    displayEmpty
                    value={selectedSubcategory ? selectedSubcategory : "all"}
                    onChange={(e) => handleSelectSubcategory(e.target.value)}
                    input={<OutlinedInput />}
                    sx={{
                      width: { xs: "100% !important", md: "90% !important" },
                    }}
                    style={{
                      backgroundColor: "var(--default-background-color)",
                      height: 35,
                      fontSize: 15,
                      flex: 1,
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 200,
                        },
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem key={"all"} value={"all"}>
                      Toutes
                    </MenuItem>
                    {groupedImages[selectedCategory].subcategories &&
                      Object.keys(
                        groupedImages[selectedCategory].subcategories
                      ).map((subcategory) => (
                        <MenuItem key={subcategory} value={subcategory}>
                          {subcategory}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                  gap: 20,
                  overflowY: "scroll",
                  height: "400px",
                }}
              >
                {selectedCategory && groupedImages[selectedCategory]
                  ? (selectedSubcategory &&
                    groupedImages[selectedCategory].subcategories[
                      selectedSubcategory
                    ]
                      ? groupedImages[selectedCategory].subcategories[
                          selectedSubcategory
                        ]
                      : groupedImages[selectedCategory].images
                    ).map((image) => (
                      <img
                        onClick={() => handleImageClick(image)}
                        key={image.name}
                        src={image.image}
                        alt={image.name}
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: 10,
                          objectFit: "cover",
                          border: imageSelected
                            ? image.name === imageSelected.name
                              ? "2px solid var(--create-product-primary-button-color)"
                              : ""
                            : "",
                        }}
                      />
                    ))
                  : imageListToUse.map((image) => (
                      <img
                        onClick={() => handleImageClick(image)}
                        key={image.name}
                        src={image.image}
                        alt={image.name}
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: 10,
                          objectFit: "cover",
                          border: imageSelected
                            ? image.name === imageSelected.name
                              ? "2px solid var(--create-product-primary-button-color)"
                              : ""
                            : "",
                        }}
                      />
                    ))}
              </div>
            </div>
          </Container>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
              padding: 24,
              gap: 10,
              backgroundColor: "var(--default-background-color)",
            }}
          >
            <CustomListButton
              primary={false}
              text={"Mettre mon image"}
              onClick={handleCustomImageClick}
              big={true}
              type={"button"}
            />
            <input
              type="file"
              id="customImageUpload"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleCustomImageUpload}
            />
            <CustomListButton
              text={"Sauvegarder"}
              onClick={() => handleClose()}
              big={true}
              type={"submit"}
            />
          </div>
        </form>
      </>
    </CustomModal>
  );
}
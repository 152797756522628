import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function TooltipMenu({ itemsMenu, height = 15 }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 0, display: "flex" }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <MoreVertIcon style={{ height: height }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "25px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {itemsMenu
            .filter((item) => !item.hidden)
            .map((item) => (
              <MenuItem
                className={"navbarItem"}
                key={item.name}
                onClick={() => {
                  item.action();
                  handleCloseUserMenu();
                }}
              >
                <Typography textAlign="center">{item.name}</Typography>
              </MenuItem>
            ))}
        </Menu>
      </Box>
    </>
  );
}

export default TooltipMenu;

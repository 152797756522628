import * as React from "react"
const SoupIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M134 .9c-13.6 4.2-28.8 17.9-35.9 32.5-5.8 11.9-7.4 19-6.9 31.6 1.1 25.9 15.1 46.4 39.8 58.2 13 6.2 18.5 13.8 18.4 25.8 0 11.1-5.2 18.8-16.3 24.4-15 7.6-17.2 24.5-4.6 34.2 3 2.2 5 2.9 9.9 3.2 5.6.3 6.9 0 14.6-3.9 6.5-3.2 10.3-6 16-11.8 13.3-13.4 19.3-27.3 19.4-45.1.1-11.9-1.2-18.5-5.9-28.5-6.6-14.3-16.4-24.2-32-32.1-14.2-7.3-19.2-13.4-20.2-24.4-1-11.2 4.8-21.4 14.9-26.2 9.4-4.5 13.8-10.6 13.8-19.2 0-5.6-1.4-9.3-4.8-13-5.1-5.5-13.3-7.8-20.2-5.7zM239.9 1c-12.6 3.8-29.7 18.9-36.1 32-5 10.2-7 18.7-7 29.5 0 26.1 14.8 48.9 38.9 60.2 4 1.8 8.9 4.8 11 6.6 8.3 7.3 10.9 21.2 5.7 31.5-3 6-6.1 8.9-14.5 13.4-12.8 6.8-15.7 20.1-6.6 30.5 6.8 7.7 15.3 8.4 27.8 2.1 12.1-6 22.3-16.4 28.3-29 5.5-11.5 7-17.8 6.9-29.3-.1-18.2-6.1-32.3-19.3-45.6-7.2-7.2-10.2-9.4-19.7-14.3-15-7.8-19.3-13.6-19.3-26.5 0-10.4 5.9-19 16.8-24.5 5.6-2.9 8.6-5.9 10.7-10.8 6.2-14.9-8.1-30.5-23.6-25.8z" />
    <path d="M471.5 129.9c-2.7 1.3-20 18-52.5 50.5L370.5 229h-170c-115.5 0-171.2.3-173.8 1.1-5.1 1.4-10.2 6.1-12.2 11.3-1.5 3.8-1.6 8.6-1.2 40.2.5 39.8 1 44.3 7.3 63.1 7.6 22.6 18.9 41 35.7 58.2 26.7 27.2 58.7 41.5 100 44.7l7.7.6v24.1c0 26.3.6 29.3 6.1 34.4 5.9 5.4 3.9 5.3 83.1 5.3 69.1 0 73.5-.1 77.3-1.9 4.8-2.2 7.9-5.4 10-10.3 1.2-3 1.5-8.3 1.5-27.7v-23.9l7.8-.6c26.6-2.1 47.5-8.1 67.7-19.7 39.2-22.5 65.9-60.9 73.6-105.7 1.6-9.7 1.9-16.2 1.9-44.5 0-30-.2-33.6-1.9-37.2-2.2-5-6.9-9.1-11.9-10.5-2.1-.5-14.6-1-28.7-1h-25l35.2-35.3c23.8-23.8 35.7-36.4 36.8-38.9 6.6-16.1-10.3-32.3-26-24.9zM454 287.4c0 21.1-1.2 30.8-5.6 44.8-11.2 35.5-41.7 63.8-78.9 73.2-12.3 3.1-28 3.6-116.5 3.6s-104.2-.5-116.5-3.6c-31.4-7.9-58.3-29.4-72.9-58.2-3.9-7.7-8.3-21.4-10-31.7-1.1-6-1.6-15.3-1.6-28.3V268h402v19.4zM303 460.5V473H203v-25h100v12.5z" />
  </svg>
)
export default SoupIcon

import * as React from "react"
const NigiriIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M225 64.6c-18.2 2-24.2 2.9-35.4 5-42.3 8.2-82 26.1-112.6 50.7C29.6 158.5 1 218.9.6 281.5c-.1 14.9-.1 15.1 3.1 21.7 3.4 7.2 9.6 14 15.6 17.3l3.7 2v33.1c0 37.4.7 42.2 7.7 56.4 5.4 10.8 17.9 23.2 29 28.7 15.7 7.9 1.2 7.4 201.8 7.1l179-.3 6.5-2.2c10-3.5 18.8-9.1 27.1-17.4 6.2-6.1 8.6-9.4 12.3-17 7-14.4 7.6-19.1 7.6-58.1v-33.3l3.5-2.3c4.4-3 7.9-7.7 11.3-15 2.6-5.4 2.7-6.4 2.6-20.7-.2-37.1-10.4-74.7-28.9-106-31.1-52.9-86.6-90.4-155-104.9-23.3-4.9-34-5.8-67.5-6.1-17.3-.2-33.1-.1-35 .1zm74 31.8c11.8 1.6 19.7 3 26.3 4.7l3.7 1v6.7c0 7.8-2.4 29-4.6 41.2-5.5 30-16.2 61.5-31 91.5l-7.4 15h-34.8l-34.9.1 3.5-7.6c19.7-43.2 30.4-88.6 31.9-135.9l.6-19.4 17.6.6c9.7.3 22.8 1.3 29.1 2.1zm-77.3 16.8c-1.6 46.9-12.6 90.1-33.6 132.1l-7.6 15.2-12 1.7c-19 2.8-38.7 6.6-56.4 10.8-9 2.2-16.7 4-17.2 4-.4 0 3.6-8.9 8.9-19.8 22.4-45.3 34.9-87.2 39.2-131.7.6-6 1.2-11 1.3-11.1.1-.1 4.5-1.8 9.7-3.7 17-6.3 39.2-11.7 60-14.6 3.6-.5 6.9-.9 7.4-1 .5 0 .7 7.5.3 18.1zm158.8 7.3c6.1 3 15.1 8.1 20 11.3 8.7 5.6 24.5 18.3 29.4 23.5l2.3 2.6-2.2 10.3c-4.3 20.3-10.2 39.7-17.4 57.3-4.3 10.3-18.7 38.8-21.5 42.4l-1.8 2.4-16.4-3.1c-9-1.8-24.7-4.3-34.8-5.6-10.1-1.4-18.5-2.7-18.7-2.9-.2-.2 2.8-7.4 6.6-15.9 18.8-42 29.4-82 32.6-123l.6-8.7 5.2 2c2.8 1.1 10.1 4.4 16.1 7.4zM110 145.7c-7.7 43.5-23.9 85.4-49.1 127.8-11.1 18.5-10.5 17.8-15.7 19.8-6.4 2.6-10.6 2.2-13.2-1.1-2.1-2.5-2.2-3.4-1.6-13.7 1.7-28.4 7.7-52.5 19.1-76 10.6-21.8 24.6-40.4 41.5-55 10.1-8.7 20.4-15.9 20.8-14.6.2.5-.6 6.3-1.8 12.8zm353.5 57.7c9.7 20.7 15.6 44.2 17.7 70.4 1.1 14.7 1.1 15.3-.9 18-1.1 1.5-3.2 2.8-4.8 3-1.5.2-13.7-3.2-27.9-7.9-13.8-4.5-25.6-8.3-26.3-8.5-.7-.2 1.6-5.7 6.2-14.6 10.8-20.9 19.2-41.7 24.9-61.8 1.5-5.2 2.9-10.1 3.2-10.9.7-2.1 2.7 1.1 7.9 12.3zM288 287c59.4 3.4 120.1 15.9 170.3 35l5.8 2.2-.3 32.6-.3 32.7-3.2 6.7c-3.8 8.1-10.2 14.6-18.1 18.5l-5.7 2.8h-356l-5.7-2.7c-7.4-3.4-14.6-10.7-18.4-18.3l-2.9-6-.3-34.1-.3-34.1 14.8-5.2c68.5-23.8 145.9-34.3 220.3-30.1z" />
    <path d="M247.9 332.3c-8.9 5.9-8.9 18.5 0 24.4 7.1 4.8 17.5 2 21.3-5.7 5-9.8-2.1-21-13.2-21-3.2 0-5.8.7-8.1 2.3zM124.3 362c-8.8 5.3-8.8 19.7 0 25 4.3 2.7 11.9 2.6 16.2 0 8.8-5.5 8.5-20-.7-25.4-3.7-2.2-11.6-2-15.5.4zM372 361.6c-9 5.6-9.3 20-.5 25.4 4.3 2.6 11.9 2.7 16.2 0 8.8-5.3 8.8-19.7 0-25-3.9-2.4-12.1-2.6-15.7-.4z" />
  </svg>
)
export default NigiriIcon

import React from "react";
import { useAuth } from "../contexts/AuthContext";
import Loading from "../components/imported/Loading/Loading";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element }) => {
    const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <Loading />;
  } else if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  } else {
    return element;
  }
};

export default PrivateRoute;

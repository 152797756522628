import * as React from "react";
import ProductListItemContainer from "../common/ProductListItemContainer";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { Container } from "@mui/material";

export default function SettingsButton({
  showContent,
  handleShowContent,
  title,
  subTitle,
  icon,
  children,
}) {
  //const [showContent, setShowContent] = React.useState(false);

  return (
    <ProductListItemContainer
      sx={{ padding: "0px !important" }}
      style={{
        borderTopRightRadius: "var(--border-radius-card)",
        borderTopLeftRadius: "var(--border-radius-card)",
        borderBottomLeftRadius: "var(--border-radius-card)",
        borderBottomRightRadius: "var(--border-radius-card)",
        border: "1px solid var(--border-color)",
        padding: "0px !important",
        margin: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          flex: 5,
        }}
        onClick={() => handleShowContent(showContent === title ? "" : title)}
      >
        <Container
          sx={{
            marginRight: {
              xs: "10px !important",
              sm: "20px !important",
              md: "20px !important",
            },
            marginLeft: {
              xs: "10px !important",
              sm: "20px !important",
              md: "20px !important",
            },
          }}
          style={{
            backgroundColor:
              "var(--create-product-secondary-button-hover-color)",
            height: 48,
            width: 48,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "var(--borderRadiusCard)",
            margin: 20,
            marginLeft: 20,
          }}
        >
          {icon}
        </Container>
        <Container
          sx={{
            margin: "0px !important",
            padding: "5px 0px !important",
            minWidth: { xs: 240, sm: "auto", md: "auto" },
            "& .product-card-title": {
              fontSize: 16,
            },
            "& .product-card-subtitle": {
              fontSize: { xs: 14, sm: 15, md: 15 },
            },
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            cursor: "pointer",
            flex: 3,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <span
            className="product-card-title"
            style={{
              fontWeight: "600",
              color: "var(--product-card-title-color)",
              whiteSpace: "nowrap",
              marginBottom: 4,
            }}
          >
            {title}
          </span>
          <span
            className="product-card-subtitle"
            style={{
              fontWeight: "400",
              color: "var(--product-card-title-color)",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {subTitle}
          </span>
        </Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flex: 1,
            paddingRight: 20,
          }}
        ></div>

        <Container
          sx={{
            margin: "0px !important",
            padding: "0px !important",
            maxWidth: { xs: 48, sm: 48, md: 48 },
          }}
          style={{
            borderLeft: "1px solid var(--border-color)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 88,
            borderRadius: 4,
            cursor: "pointer",
          }}
        >
          <ArrowBackIosOutlinedIcon
            sx={{
              margin: "auto 0px !important",
              height: "20px",
              width: "20px",
              transform: `rotate(${showContent === title ? 90 : 270}deg)`,
              color: "var(--border-color)",
            }}
          />
        </Container>
      </div>
      {showContent === title && children}
    </ProductListItemContainer>
  );
}

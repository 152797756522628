import * as React from "react";
import ProductListItemContainer from "../common/ProductListItemContainer";
import CreateProductModal from "../CreateProduct/CreateProductModal";
import "../index.css";
import CustomListButton from "../common/CustomListButton";
import CreateCategorieModal from "../CreateCategorie/CreateCategorieModal";

export default function ProductListFooter({
  categoryId,
  showCreateDish = true,
  alone = false,
  menuId,
}) {
  const [openDish, setOpenDish] = React.useState(false);
  const [openCategorie, setOpenCategorie] = React.useState(false);
  const handleOpenDish = () => setOpenDish(true);
  const handleCloseDish = () => setOpenDish(false);
  const handleOpenCategorie = () => setOpenCategorie(true);
  const handleCloseCategorie = () => setOpenCategorie(false);

  return (
    <ProductListItemContainer
      style={{
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: alone ? 10 : 0,
        borderTopRightRadius: alone ? 10 : 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          gap: 10,
        }}
      >
        {showCreateDish && (
          <CustomListButton text={"Nouveau Plat"} onClick={handleOpenDish} />
        )}
        <CustomListButton
          text={"Nouvelle Catégorie"}
          onClick={handleOpenCategorie}
          primary={false}
        />
        {openDish && (
          <CreateProductModal
            categoryId={categoryId}
            open={openDish}
            handleClose={handleCloseDish}
            menuId={menuId}
          />
        )}
        {openCategorie && (
          <CreateCategorieModal
            open={openCategorie}
            handleClose={handleCloseCategorie}
            menuId={menuId}
          />
        )}
      </div>
    </ProductListItemContainer>
  );
}

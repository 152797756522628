import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import { SettingsContext } from "../../Context/SettingsContext";
import { useTranslation } from "react-i18next";

function Wifi({campaignId}) {
  const { t } = useTranslation("global");
  const { settings, updateSettings } = React.useContext(SettingsContext);

  const [networkName, setNetworkName] = React.useState(
    settings?.wifi?.networkName
  );
  const [password, setPassword] = React.useState(settings?.wifi?.password);

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 20,
        borderTop: "1px solid var(--border-color)",
      }}
    >
      <div
        style={{
          gap: 15,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddItemInputContainer
          setter={setNetworkName}
          value={networkName}
          name={t("settingsNetworkName")}
          titleFontSize={15}
        />
        <AddItemInputContainer
          setter={setPassword}
          value={password}
          name={t("textPassword")}
          titleFontSize={15}
        />
      </div>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={() => {
            updateSettings({
              wifi: {
                networkName,
                password,
              },
            }, campaignId);
          }}
        />
      </div>
    </div>
  );
}

export default Wifi;

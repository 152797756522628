import React from "react";

const BurritoIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path
        d="M2415 5114 c-67 -10 -184 -37 -240 -56 -128 -44 -302 -145 -364 -212
        -14 -14 -29 -26 -35 -26 -6 0 -47 16 -93 35 -186 80 -394 86 -593 17 -218 -76
        -406 -251 -494 -460 -35 -84 -76 -237 -76 -287 0 -73 509 -3518 531 -3590 60
        -201 198 -365 384 -455 162 -79 99 -75 1125 -75 1026 0 965 -4 1125 74 187 92
        325 256 384 456 16 53 352 2304 517 3460 14 101 15 133 5 203 -26 182 -105
        338 -240 473 -102 102 -205 166 -341 211 -89 30 -104 32 -245 32 -165 1 -236
        -14 -357 -75 -32 -16 -62 -29 -69 -29 -6 0 -37 24 -69 53 -108 97 -266 177
        -440 222 -83 21 -134 27 -245 30 -77 2 -153 1 -170 -1z m345 -320 c151 -39
        289 -122 397 -238 109 -119 169 -131 271 -55 68 52 134 85 207 104 213 55 456
        -42 577 -229 40 -62 84 -185 71 -196 -5 -4 -79 -48 -166 -98 l-158 -92 -66 55
        c-79 65 -198 126 -308 157 -114 32 -325 32 -437 1 -96 -28 -169 -61 -234 -105
        l-52 -35 -58 39 c-213 141 -494 204 -749 168 -187 -27 -383 -108 -522 -215
        l-73 -55 -60 30 c-94 47 -293 132 -423 180 -115 42 -118 44 -112 69 23 94 149
        233 258 285 99 47 163 60 266 54 108 -7 181 -33 283 -99 75 -49 80 -51 133
        -47 54 5 58 7 143 90 149 145 264 207 447 244 88 17 275 11 365 -12z m-418
        -828 c195 -38 381 -149 493 -298 47 -61 125 -212 125 -240 0 -8 -78 -58 -172
        -113 -95 -54 -180 -103 -190 -109 -13 -8 -33 6 -105 74 -173 164 -389 333
        -608 475 -66 43 -124 81 -128 85 -10 9 129 81 199 104 116 38 260 46 386 22z
        m-1218 -138 c1258 -549 2160 -1694 2360 -2993 23 -148 46 -380 46 -465 0 -66
        29 -64 -939 -68 -578 -2 -882 1 -915 8 -150 31 -279 145 -331 290 -14 38 -495
        3268 -495 3320 0 16 12 14 102 -21 56 -22 133 -54 172 -71z m2371 87 c66 -17
        166 -64 172 -80 2 -6 -90 -65 -204 -130 -113 -66 -212 -123 -219 -128 -9 -5
        -24 17 -52 75 -22 46 -56 107 -76 137 -20 29 -36 55 -36 57 0 2 23 15 50 29
        104 53 251 69 365 40z m759 -122 c-2 -10 -103 -695 -224 -1523 -121 -828 -221
        -1512 -223 -1520 -1 -8 -15 61 -30 153 -66 402 -184 768 -367 1142 -144 293
        -295 530 -507 795 -58 72 -103 133 -102 135 2 1 310 180 684 396 374 216 698
        403 720 416 48 27 54 28 49 6z"
      />
    </g>
  </svg>
);

export default BurritoIcon;

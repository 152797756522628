import React from "react";
import { API_URL } from "../consts";
// TODO doliaffiliate
// import { UserContext } from "./UserContext";
import { SettingsContext } from "./SettingsContext";

export const MenusContext = React.createContext();

export const MenusProvider = ({ children }) => {
  const [currentMerchantId, setCurrentMerchantId] = React.useState(null);
  const [menus, setMenus] = React.useState([]);
  const [code, setCode] = React.useState({hasCode: true, code: ""});

  const fetchMenus = async (campaignId) => {
    if (!campaignId) return;
    const response = await fetch(
      `${API_URL}api/marchants/${campaignId}/menus`
    );
    console.log("[fetchMenus]response", response);
    console.log(`${API_URL}api/marchants/${campaignId}/menus`);
    const data = await response.json();
    console.log(data);
    setMenus(data.menus);
    setCode({hasCode: data.hasCode, code: data.code});
  };

  const deleteMenu = async (menuId) => {
    try {
      await fetch(`${API_URL}api/menus/${menuId}`, {
        method: "DELETE",
      });
      setMenus(menus.filter((menu) => menu._id !== menuId));
    } catch (error) {
      console.error("Error deleting menu:", error);
    }
  };

  const moveMenu = async (menuId, direction) => {
    try {
      const response = await fetch(
        `${API_URL}api/menus/${menuId}/${direction}`,
        {
          method: "PUT",
        }
      );
      const { upadatedMenu, menuMovedUpdate } = await response.json();
      const updatedMenus = menus.map((menu) => {
        if (menu._id === menuMovedUpdate._id) {
          return { ...menuMovedUpdate, categories: menu.categories };
        }
        if (menu._id === menuId) {
          return { ...upadatedMenu, categories: menu.categories };
        }
        return menu;
      });
      setMenus(updatedMenus);
    } catch (error) {
      console.error(`Error moving menu ${direction}:`, error);
    }
  };

  const addMenu = async (name, description, icon, campaignId, closeModal) => {
    if (!name || !campaignId) {
      console.log('!name || !currentMerchantId', name, campaignId)
      return;
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        description: description ?? "",
        icon: icon ?? "fork",
        marchantId: campaignId,
      }),
    };
    const response = await fetch(`${API_URL}api/menus`, options);
    const data = await response.json();
    console.log("[addMenu] response:", data);
    if (!data) {
      return;
    }
    if (!data.needToPay) {
      setMenus([...menus, data]);
      if (closeModal) {
        closeModal();
      }
    } else {
      window.location.replace('/user/dashboard?upgrade=true')
    }
  };

  const updateMenu = async (
    id,
    name,
    description,
    icon,
    closeModal,
    closeModalAction
  ) => {
    if (!name) {
      return;
    }
    try {
      console.log("try {");
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          description: description ?? "",
          icon: icon ?? "",
        }),
      };
      const response = await fetch(`${API_URL}api/menus/${id}`, options);
      const data = await response.json();
      console.log("[updateMenu] response:", data);
      const updatedMenus = menus.map((menu) => {
        if (menu._id === id) {
          return {
            ...menu,
            name: data.name,
            description: data.description,
            icon: data.icon,
          };
        }
        return menu;
      });

      setMenus(updatedMenus);
      if (closeModal && closeModalAction) {
        closeModalAction(data.icon);
        closeModal();
        return;
      }
    } catch (error) {
      console.error("Error updating menu:", error);
    }
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <MenusContext.Provider
      value={{
        menus,
        deleteMenu,
        moveMenu,
        addMenu,
        updateMenu,
        setCurrentMerchantId,
        currentMerchantId,
        fetchMenus,
        code,
      }}
    >
      {children}
    </MenusContext.Provider>
  );
};

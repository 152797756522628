import React from "react";
import Container from "@mui/material/Container";
import SettingsButton from "./SettingsButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BasicInformation from "./Content/BasicInformation";
import { useTranslation } from "react-i18next";
import Appearance from "./Content/Apparence";
import Wifi from "./Content/Wifi";
import SocialNetworks from "./Content/SocialNetworks";
import SuccessModal from "../common/SuccessModal";
import ProductListItemContainer from "../common/ProductListItemContainer";
import Review from "./Content/Review";
import { SettingsContext } from "../Context/SettingsContext";
import { useParams } from "react-router-dom";

function Settings() {
  const { t } = useTranslation("global");
  const { campaignId } = useParams();

  const { changesModal, setChangesModal, settings, fetchSettings } =
    React.useContext(SettingsContext);
  const [showContent, setShowContent] = React.useState("");

  const size = 32;
  const settingsList = [
    {
      title: t("settingsBasicInformationsTitle"),
      subTitle: t("settingsBasicInformationsSubTitle"),
      icon: <InfoOutlinedIcon sx={{ height: size }} />,
      children: <BasicInformation campaignId={campaignId}/>,
    },
    {
      title: t("settingsApparenceTitle"),
      subTitle: t("settingsApparenceSubTitle"),
      icon: <ColorLensOutlinedIcon sx={{ height: size }} />,
      children: <Appearance campaignId={campaignId}/>,
    },
    {
      title: t("settingsReviewTitle"),
      subTitle: t("settingsReviewSubTitle"),
      icon: <ChatOutlinedIcon sx={{ height: size }} />,
      children: <Review campaignId={campaignId}/>,
    },
    {
      title: t("settingsWifiTitle"),
      subTitle: t("settingsWifiSubTitle"),
      icon: <WifiOutlinedIcon sx={{ height: size }} />,
      children: <Wifi campaignId={campaignId}/>,
    },
    {
      title: t("settingsSocialNetworksTitle"),
      subTitle: t("settingsSocialNetworksSubTitle"),
      icon: <ShareOutlinedIcon sx={{ height: size }} />,
      children: <SocialNetworks campaignId={campaignId}/>,
    },
  ];

  const handleShowContent = (title) => {
    setShowContent(title);
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0px !important",
          gap: 10,
          marginBottom: 56,
        }}
        sx={{
          padding: { xs: "0px !important", md: "0px 24px !important" },
          "--border-radius-card": {
            xs: "0px",
            md: "8px",
          },
        }}
      >
        <Container
          sx={{
            padding: { xs: "0px 16px !important", md: "0px !important" },
            "--border-radius-card": {
              xs: "0px",
              md: "8px",
            },
          }}
        >
          <ProductListItemContainer
            style={{
              marginBottom: 15,
              paddingBottom: 15,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: "none",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid var(--border-color)",
            }}
          >
            <h2 style={{ margin: 0 }}>{t("settingsTitle")}</h2>
          </ProductListItemContainer>
        </Container>

        <Container
          sx={{
            padding: "0px !important",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {settingsList.map((setting, index) => (
            <SettingsButton
              key={index}
              title={setting.title}
              subTitle={setting.subTitle}
              icon={setting.icon}
              children={setting.children}
              handleShowContent={handleShowContent}
              showContent={showContent}
            />
          ))}
        </Container>
      </Container>
      {changesModal && (
        <SuccessModal
          open={changesModal}
          handleClose={() => {
            setChangesModal(false);
            setShowContent("");
          }}
        />
      )}
    </>
  );
}

export default Settings;

import * as React from "react"
const MuffinIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M237.6 1.2c-69.1 6-131.8 48.5-163 110.4-8.5 16.9-13.7 31.8-18.9 54.5-3 13-8.4 20.9-19.1 28.1C18.5 206.3 8.8 218.9 2.9 238c-2 6.2-2.4 9.7-2.4 20s.4 13.8 2.4 20c6.5 20.9 19 35.7 38.3 45.5 4.8 2.4 11.1 4.8 14 5.4l5.3 1.1 14.3 85.8c16 95.2 15 91.5 23.4 94.6 3.9 1.5 18 1.6 158.5 1.4 153.9-.3 154.2-.3 156.9-2.4 6-4.5 5.7-3 22.1-93l15.6-86.2 5.3-1.1c7.1-1.6 19.6-7.8 27-13.5 11.8-8.9 22.1-24.5 26.1-39.6 2.7-10 2.4-28.4-.6-38-5.8-18.8-15.3-31.3-32.3-42.8-13.4-9.1-18.1-16.4-22.3-35.2-6.4-28.6-18.6-55.2-36.2-78.8-7.3-9.7-24.5-27.6-34.3-35.5-24.8-20.1-56.5-34.9-87.5-41.2C276 .4 258-.6 237.6 1.2zM271.1 31c23 2.3 41.5 7.6 62.4 18 27.7 13.8 49.7 33.1 66.8 58.8 11.8 17.7 19.5 35.9 25.2 59.8 5.1 21 10 30.8 20.9 41.8 2.9 2.8 8.4 7.3 12.4 9.9 8.6 5.7 14 11.2 17.7 17.9 12.1 22.2 3.1 49.1-20.2 60.1l-6.8 3.2h-387l-6.8-3.2c-12.2-5.8-20.8-16.1-24.2-28.8-4.8-18.7 2.9-37 20.5-48.3 8.2-5.3 18.6-15.8 23.5-23.7 4.9-8 8.1-16.9 12-33.5 15-64.6 67.8-115.6 133.7-129.1 18.2-3.7 33-4.5 49.9-2.9zM133.4 334.7c.3 2.1 3.7 34.2 7.6 71.3 3.9 37.1 7.3 69.4 7.6 71.8l.6 4.2h-32.8l-12.3-73.3c-6.7-40.2-12.5-74.2-12.8-75.5l-.5-2.2h42l.6 3.7zm72-2.5c.4 1.3 3.7 131.4 3.6 143.5v6.3h-15c-13.6 0-15-.2-15-1.8-.1-.9-3.6-34.6-7.9-74.7-4.3-40.2-7.6-73.3-7.4-73.8.2-.4 9.6-.7 20.9-.7 15.5 0 20.6.3 20.8 1.2zm70.6 2.5c0 2.1-1.1 34.6-2.5 72.3-1.4 37.7-2.5 70-2.5 71.7v3.3h-15.5c-11.6 0-15.7-.3-16-1.3-.3-1.2-3.6-130.4-3.5-143V331h40v3.7zm70.6 0C345.9 339 332 478 332 480.4c0 1.4-2 1.6-15.5 1.6H301v-3.3c0-1.7 1.1-34 2.5-71.7s2.5-70.2 2.5-72.3V331h41.2l-.6 3.7zm73.4-1.5c0 1.3-6 35.1-13.3 75.3l-13.3 73-15.7.3c-11.6.2-15.7-.1-15.7-.9 0-1.6 13.8-139 14.6-145.2l.6-4.7H420v2.2z" />
    <path d="M246.1 65.1c-12.8 2.2-27.6 13.1-33.5 24.7-16.7 33 8.2 71.2 44.9 68.9 23.9-1.5 42.1-19.5 44.2-43.7 2-23.6-15.9-46.2-39.7-49.9-7.4-1.2-8.4-1.2-15.9 0zm17.4 31.8c6 3.5 9.4 11.4 8.1 18.7-.9 4.5-6.7 10.9-11.3 12.5-17.4 5.7-30.9-16.3-17.6-28.8 5.8-5.5 14-6.4 20.8-2.4zM155.6 102c-9.4 2.9-13.5 15.7-7.5 23.4 9.1 11.5 28 4.2 26.6-10.3-1-9.4-10.3-15.8-19.1-13.1zM344.3 155.5c-16.2 4.4-27.6 15.8-31.9 32-5.2 19.2 4.9 40.2 23.5 49.3 7.4 3.6 7.9 3.7 18.6 3.7 10.3 0 11.4-.2 17.7-3.2 19.7-9.3 29.6-30.1 24.3-50.8-5.9-22.7-30-36.9-52.2-31zm15.5 29.9c13.1 6.8 9.2 25.6-5.3 25.6-7.4 0-13.5-6.2-13.5-13.8 0-5.9 7.6-13.2 13.8-13.2 1.3 0 3.5.6 5 1.4zM135.3 179.9c-6.4 2.3-6.6 2.5-13 7.3-6.3 4.8-11.9 13-14.5 21.2-2.9 9.1-2.1 21.2 2 30 4.3 9.1 11.8 16.8 20.8 21.3 9.5 4.6 21.3 5.6 31 2.5 12.2-3.8 22-12.7 27.6-24.7 1.9-4.3 2.3-6.7 2.3-16.5 0-11.2-.1-11.8-3.4-18.5-4.5-9.1-12-16.6-20.8-20.8-6-2.8-8-3.2-16.8-3.4-7.9-.3-11.1.1-15.2 1.6zm22.8 32c3.5 3.5 3.9 4.4 3.9 9 0 4.4-.5 5.6-3.3 8.7-10.2 11.1-28.1-1.2-21.2-14.6 2.5-4.8 6.2-7 11.8-7 4.4 0 5.3.4 8.8 3.9zM249.1 221.8c-5.4 2.7-7.6 6.5-7.6 12.7 0 8.8 6 15 14.5 15 8.4 0 14.5-6.3 14.5-14.8 0-11.4-11.2-18.2-21.4-12.9zM405.4 242c-10.3 5.1-11.1 19.3-1.4 25.8 4.4 3 12.1 2.7 16.8-.6 3.9-2.8 6.7-9.8 5.8-14.7-.9-4.7-5.9-10.2-10.4-11.5-5.1-1.4-6.1-1.3-10.8 1z" />
  </svg>
)
export default MuffinIcon

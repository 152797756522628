import * as React from "react";
import "../index.css";
import { Container } from "@mui/material";

export default function CustomListButton({
  onClick,
  text,
  primary = true,
  backgroundColorProps,
  backgroundColorHoverProps,
  borderColorProps,
  big = true,
  type,
  iconBefore,
  minWidth = "64px",
  styles,
  children,
}) {
  return (
    <Container
      onClick={() => onClick()}
      variant="outlined"
      sx={{
        color: primary ? "white" : "black",
        backgroundColor: backgroundColorProps
          ? backgroundColorProps
          : primary
          ? "var(--create-product-primary-button-color)"
          : "var(--create-product-secondary-button-color)",
        "&:hover": {
          backgroundColor: backgroundColorHoverProps
            ? backgroundColorHoverProps
            : primary
            ? "var(--create-product-primary-button-hover-color)"
            : "var(--create-product-secondary-button-hover-color)",
          borderColor: borderColorProps
            ? borderColorProps
            : primary
            ? "var(--create-product-primary-button-border-color)"
            : "var(--create-product-secondary-button-border-color)",
        },
        textTransform: "none",
        border: "1px solid",
        borderColor: borderColorProps
          ? borderColorProps
          : primary
          ? "var(--create-product-primary-button-border-color)"
          : "var(--create-product-secondary-button-border-color)",
        minWidth: minWidth,
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        display: "inline-flex",
        borderRadius: "4px",
        fontWeight: big ? 500 : 500,
        padding: big ? 1 : "",
        fontSize: big ? 15 : 14,
        width: 'auto',
        lineHeight: "20px",
        margin:'0px',
        cursor: "pointer",
        textAlign: "center",
        ...styles,
        
      }}
      type={type}
    >
      {iconBefore && iconBefore}
      {text}
      {children && children}
    </Container>
  );
}

import * as React from "react";
import ProductListItemContainer from "./ProductListItemContainer";
import TooltipMenu from "./TooltipMenu";
import { iconsList } from "../iconsList";

export default function ProductCard({
  name,
  description,
  price,
  image,
  customImage,
  propsIcon,
  first,
  settings,
  alone = false,
  showTooltip = true,
  padding = 0,
  onClick,
  children
}) {
  console.log("image", image);
  console.log("customImage", customImage);
  return (
    <ProductListItemContainer
      style={{
        borderTopRightRadius: first || alone ? 10 : 0,
        borderTopLeftRadius: first || alone ? 10 : 0,
        borderBottomLeftRadius: alone ? 10 : 0,
        borderBottomRightRadius: alone ? 10 : 0,
        borderTop: first ? "1px solid var(--border-color)" : "",
        padding: padding,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          flex: 5,
        }}
      >
        {propsIcon && (
          <div
            style={{
              marginLeft: "20px",
              width: "20px",
              height: "20px",
              display: "flex",
            }}
          >
            {iconsList.find((icon) => icon.name === propsIcon).icon}
          </div>
        )}
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflowWrap: "break-word",
            cursor: "pointer",
            height: "100%",
            width: "100%",
            padding: 20,
          }}
          onClick={onClick}
        >
          <span
            style={{
              fontWeight: "500",
              color: "var(--product-card-title-color)",
            }}
          >
            {name}{" "}
          </span>
          <span
            style={{
              fontWeight: "400",
              color: "var(--product-card-title-color)",
            }}
          >
            {description ? `- ${description}` : ""}
          </span>
        </div>

        {children && <>{children}</>}
        {price && (
          <div
            style={{
              fontSize: 15,
              fontWeight: "400",
              color: "var(--product-card-title-color)",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              paddingRight: 5,
              overflow: "hidden",
              textOverflow: "clip",
              whiteSpace: "nowrap",
              overflowWrap: "break-word",
            }}
          >
            {price ? `${price} €` : ""}
          </div>
        )}
        {image || customImage ? (
          <img
            src={image || customImage}
            style={{
              width: "50px",
              height: "50px",
              maxHeight: "50px",
              objectFit: "cover",
              borderRadius: 4,
            }}
          />
        ) : null}
        {showTooltip && settings && settings.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: 20,
            }}
          >
            {
              //TODO(chris) : Add display option
              /*
              <IOSSwitch defaultChecked />
            */
            }
            <TooltipMenu height={20} itemsMenu={settings} />
          </div>
        )}
      </div>
    </ProductListItemContainer>
  );
}

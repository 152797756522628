import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import Register from "./components/Register";
import Login from "./components/Login";
import CampaignList from "./components/CampaignList";
import PromoCodeStats from "./components/PromoCodeStats";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import CreateCampaign from "./components/CreateCampaign";
import { MenusProvider } from "./components/imported/Context/MenusContext";
import { CategoriesProvider } from "./components/imported/Context/CategoriesContext";
import ProductsList from "./components/imported/ProductsList/ProductsList";
import CreatePromoCode from "./components/CreatePromoCode";
import { SettingsProvider } from "./components/imported/Context/SettingsContext";

function Navigation() {
  const { isAuthenticated, logout } = useAuth();

  return (
    <nav>
      <ul>
        {!isAuthenticated && (
          <>
            <li>
              <Link to="/register">Register</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
          </>
        )}
        {isAuthenticated && (
          <>
            <li>
              <Link to="/campaign">Create Campaign</Link>
            </li>
            <li>
              <Link to="/promo-stats">Promo Code Stats</Link>
            </li>
            <li>
              <button onClick={logout}>Logout</button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

function App() {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Navigation />
      <Routes>
        <Route
          path="/register"
          element={
            <PublicRoute
              element={
                <>
                  <Register />
                </>
              }
            />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute
              element={
                <>
                  <Login />
                </>
              }
            />
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute
              element={
                <>
                  <CampaignList />
                </>
              }
            />
          }
        />
        <Route
          path="/campaign"
          element={
            <PrivateRoute
              element={
                <>
                  <CampaignList />
                </>
              }
            />
          }
        />
        <Route
          path="/campaign/:campaignId/:campaignName"
          element={
            <PrivateRoute
              element={
                <>
                  <CreateCampaign />
                </>
              }
            />
          }
        />
        <Route
          path="/campaign/:campaignId/:campaignName/:menuId"
          element={
            <PrivateRoute
              element={
                <>
                  <ProductsList />
                </>
              }
            />
          }
        />
        <Route
          path="/campaign/:campaignId/:campaignName/create"
          element={
            <PrivateRoute
              element={
                <>
                  <CreatePromoCode />
                </>
              }
            />
          }
        />
        <Route
          path="/promo-stats"
          element={
            <PrivateRoute
              element={
                <>
                  <PromoCodeStats />
                </>
              }
            />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

function AppWithAuth() {
  return (
    <SettingsProvider>
      <CategoriesProvider>
        <MenusProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </MenusProvider>
      </CategoriesProvider>
    </SettingsProvider>
  );
}

export default AppWithAuth;

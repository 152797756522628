import * as React from "react"
const RiceIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 128 128"
    fill={color}
  >
    <path d="M.7 9.7c-1.8 1.8.1 4.6 9.2 13.7l9.8 9.8-1.1 3.6c-.6 2.1-2.8 5.3-4.8 7.3-2.4 2.3-4.1 5.2-4.9 8.2-.8 3.5-1.6 4.7-3.3 4.9-2 .3-2.1.8-2 8.8.2 13.2 6.4 27.1 16.9 37.5 3.9 4 4.4 5 4.7 9.7l.3 5.3h77l.3-5.3c.3-4.7.8-5.7 4.9-10 10.6-10.9 16.5-23.9 16.7-37.2.1-8 0-8.5-2-8.8-1.7-.2-2.5-1.4-3.4-4.9-.7-3.2-2.3-5.8-4.8-8.2-2-1.9-4.2-5.1-4.8-7.2l-1.1-3.7 9.9-9.9c10.3-10.2 11.9-13.4 6.9-14.1-1.9-.3-4.4 1.6-12.1 9.3l-9.7 9.7-4.1-1.4c-2.3-.8-5.1-2.7-6.3-4.2C90.1 19 85.3 17 79.5 17c-2.5 0-6-.7-7.7-1.6-1.7-.9-5.2-1.6-7.8-1.6-2.6 0-6.1.7-7.8 1.6-1.7.9-5.2 1.6-7.7 1.6-5.5 0-10.8 2.3-13.6 5.8-1.1 1.4-3.8 3.2-6.1 4l-4.1 1.4-9.6-9.6C6.2 9.7 2.7 7.6.7 9.7zM70 23.5c3.2 2.2 4 2.4 6.8 1.4 4.6-1.6 7.6-.5 11.3 4.1 2 2.4 4.1 4 5.4 4 3.9 0 3.6 1.3-1.1 6.1s-5.3 7.3-2.2 9.3c1.5.9 2.6.2 6.6-3.7 2.6-2.6 5.1-4.7 5.5-4.7.4 0 .7 1.3.7 3 0 2.3.7 3.3 2.9 4.5 3.4 1.7 6.1 5.1 6.1 7.7 0 1.7-2.7 1.8-48 1.8-47.9 0-48 0-48-2.1 0-1.8 2.5-4.8 7.4-8.7.9-.7 1.6-2.4 1.6-3.7 0-1.4.3-2.5.7-2.5.4 0 2.9 2.1 5.5 4.7 4 3.9 5.1 4.6 6.6 3.7 3.1-2 2.5-4.5-2.2-9.3-4.9-5-5-6.1-.5-6.1 2.1 0 3.2-.7 4.4-3 2.5-4.8 5.6-6.3 11.5-5.5 4.2.6 5.4.4 7.6-1.4 3.5-2.8 7-2.6 11.4.4zm47.5 44.2c-2.1 12.3-8.4 24.3-17 32.2-3.9 3.6-4.5 4.7-4.5 8.1v4H32v-4c0-3.4-.6-4.5-4.5-8.1-8.6-7.9-14.9-19.9-17-32.2L9.9 64h108.2l-.6 3.7z" />
  </svg>
)
export default RiceIcon

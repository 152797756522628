import * as React from "react";
import TextField from "@mui/material/TextField";
import EuroIcon from "@mui/icons-material/Euro";

import { InputAdornment } from "@mui/material";
/**
| "button"
| "checkbox"
| "color"
| "date"
| "datetime-local"
| "email"
| "file"
| "hidden"
| "image"
| "month"
| "number"
| "password"
| "radio"
| "range"
| "reset"
| "search"
| "submit"
| "tel"
| "text"
| "time"
| "url"
| "week"
 */

export default function BasicTextFields({
  placeholder,
  type,
  rows = 1,
  setter,
  price,
  value,
  required,
  borderRadiusProps,
  startAdornment,
  autoComplete = false,
  inputValueProps,
  heightProps = 30,
  disabled = false,
  onClick,
  name,
  fontSize,
}) {
  const [inputValue, setInputValue] = React.useState(value ? value : "");
  React.useEffect(() => {
    setInputValue(inputValueProps);
  }, [inputValueProps]);

  const onTextFieldChange = (value) => {
    setInputValue(value);
    setter(value);
  };

  return (
    <TextField
      id={name ?? "fullWidth"}
      name={name}
      onClick={onClick}
      autoComplete={autoComplete.toString()}
      fullWidth
      multiline={rows > 1 ? true : false}
      rows={rows}
      placeholder={placeholder}
      type={type}
      required={required}
      value={inputValue}
      onChange={(e) => onTextFieldChange(e.target.value)}
      disabled={disabled}
      sx={{
        ".MuiInputBase-root": {
          borderRadius: borderRadiusProps ?? "4px",
          /*
            TODO(chris): is here the description bug but affect other textfield
            padding: "0px 0px 0px 0px !important",
          */
        },
        "> *": { height: rows * heightProps },
        ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
          fontSize: 12,
          height: rows * heightProps - 10,
          position: "absolute",
          "-webkit-transform": "none !important",
          paddingTop: "8px !important",
          paddingLeft: "8px !important",
        },
        fieldset: {
          borderColor: "var(--border-color)",
        },
      }}
      inputProps={{
        style: {
          padding: "0px 0px 0px 8px",
          fontSize: fontSize ?? "1rem",
        },
      }}
      style={{ padding: "0px !important" }}
      InputProps={{
        endAdornment: price ? (
          <InputAdornment position="end">
            <EuroIcon />
          </InputAdornment>
        ) : (
          <></>
        ),
        startAdornment: startAdornment,
      }}
    />
  );
}

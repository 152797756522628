import * as React from "react"
const FishIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M295.5 117.7c-39.6 1.7-79 13.8-112.6 34.8-15.8 9.9-40.4 31.6-51.1 45.2-1.8 2.3-3.6 4.2-3.9 4.2-.3.1-2.6-4.1-5.2-9.2-18-36-56.9-57.3-96.4-52.9-17.8 2-22.3 5.6-24.8 20-3.5 19.4-.1 41.2 9.3 59.8 6.1 12.2 21.5 29.7 30.9 35.3 1.7 1 1.7 1.2 0 2.2-9.3 5.6-23.8 21.9-30.2 34.1-10 18.9-13.6 41.1-10 61 2.5 14.3 6.9 17.9 24.4 20 39.4 4.6 78.6-16.8 96.7-52.7 2.5-4.9 4.7-9.1 4.9-9.4.2-.2 1.7 1.2 3.2 3 11.7 14.5 36.9 36.8 52.2 46.4 28.8 17.9 62.4 29.7 96.1 33.5 13.6 1.6 41.6 1.3 55.6-.5 75.6-9.8 140.4-56.4 173.4-124.6 5.1-10.5 5.1-13.3 0-23.7-13.8-28.7-35.2-55.8-60-76.4-16.8-13.9-45.5-29.9-67-37.3-26.9-9.3-58-13.9-85.5-12.8zm27.1 33.4c13 1.2 27.9 4.1 39 7.5l8.1 2.4-.4 5.3c-5.7 73.7-46.8 140.2-110.2 178.4-5.9 3.5-11.6 6.6-12.9 7-4.3 1.1-29-9.5-44.1-19-26.1-16.2-50-41.3-65.1-68.3l-4.8-8.6 5.1-8.9c24.6-42.3 61.5-72.4 107.2-87.2 24.4-8 52.7-11.1 78.1-8.6zM56 175c24.9 8.3 43.1 31.9 44.2 57.1l.3 7.4-7-.3c-22-.9-42.8-14.2-53.5-34.3-3.9-7.3-7-19.5-7-27.2v-6l8.3.5c4.5.3 11.1 1.6 14.7 2.8zm357.2 7.9c17.5 11.7 32.8 26.2 45.9 43.5 6.1 8.1 17.9 27.4 17.9 29.3 0 2-5.5 11.6-13.3 23.1-29.3 43.5-76.3 72.8-129.2 80.7-13 1.9-39.5 3.2-39.5 1.8 0-.5 3.7-3.8 8.3-7.3 10.6-8.2 35.4-33.1 43.9-44 28.9-37.4 48.5-84.5 53.3-127.8.4-3.4 1.1-6.2 1.5-6.2.5 0 5.5 3.1 11.2 6.9zm-313 97c-1.3 31-27.8 57.9-58.9 59.9l-8.3.5v-6.4c0-21.6 14.7-44.2 35.5-54.7 8.8-4.4 20.4-7.2 28.5-6.8l3.5.1-.3 7.4z" />
    <path d="M421 231.1c-8.6 3.4-14.7 13.3-13.7 22.1 2.1 17.5 21.8 25.6 35.2 14.5 12.1-10 8.9-30-5.7-36.1-4.2-1.8-12-2-15.8-.5z" />
  </svg>
)
export default FishIcon

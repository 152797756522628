import * as React from "react"
const ChickenlegIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M332.5.6c-1.6.2-6.6.9-11 1.5-23.3 3.1-47.4 12.3-68.3 26.2-10.8 7.2-24 18.6-30.3 26.2-26.8 32.1-51.2 97.7-73.4 196.9l-5.4 23.9-16.7 16.8-16.6 16.7-27.7.5c-15.2.3-29.8 1.1-32.6 1.7-7.9 1.8-22.5 9.4-28.7 15-7.7 6.9-15.1 18.2-18.4 28.3-4 11.9-4 28.5 0 40.4 5.2 15.9 16.8 29.5 31.5 37.2 9.1 4.7 16.6 6.8 28.2 7.7l8.6.7.7 8.6c.9 11.6 3 19.1 7.7 28.2 7.7 14.7 21.3 26.3 37.2 31.5 7.1 2.4 9.8 2.8 20.2 2.8 10.4 0 13.1-.4 20.2-2.8 10.1-3.3 21.4-10.7 28.3-18.4 5.6-6.2 13.2-20.8 15-28.7.6-2.8 1.4-17.4 1.7-32.6l.5-27.7 16.8-16.7 16.7-16.7 21.9-4.8c86-19 151.2-41.5 184.9-63.7 12-8 17.6-12.7 26.4-22.6 21-23.6 34.9-53.2 40.2-86.2 2-11.8 1.6-39.8-.5-52-4.8-26.9-15.9-52.4-32.2-73.6-6.2-8.2-22.1-24.1-30.3-30.3-21.1-16.2-47-27.5-73.1-32-9.2-1.6-35.5-2.9-41.5-2zM360 44.5c9.4.9 21.6 3.9 31.6 8 66.3 26.7 95.7 104.2 64 168.6-8.1 16.5-21.4 32.3-34.5 41.2-27.6 18.6-87.3 39.1-166.9 57.3-10.7 2.4-20.2 4.4-21.1 4.4-2.1 0-45.1-43-45.1-45.1 0-5 14-62.2 21.5-87.9 13.7-46.8 27.6-81.5 40.2-100.1 17-25.1 50.8-43.8 84.9-46.9 8.7-.7 12.2-.7 25.4.5zM179.5 333l14.9 15-15.6 15.7c-8.5 8.7-16.4 17.4-17.4 19.3-1.7 3.2-1.9 6.8-2.4 37-.6 37.2-.5 36.7-7.6 42.9-4.6 4.1-7.7 5.2-14 5.2-6.1 0-10.9-1.8-14.7-5.7-5.8-5.8-6.1-7.2-6.7-29.4-.5-18.4-.8-20.9-2.7-24.5-2.4-4.6-5.4-7.5-10.3-10.1-3-1.6-6.6-1.9-24-2.4-22.2-.6-23.6-.9-29.4-6.7-3.9-3.8-5.7-8.6-5.7-14.7 0-6.3 1.1-9.4 5.2-14 6.2-7.1 5.7-7 42.9-7.6l33.5-.5 4.5-2.7c2.5-1.5 11-9.2 19-17.2s14.7-14.6 15-14.6c.3 0 7.3 6.7 15.5 15z" />
  </svg>
)
export default ChickenlegIcon

import * as React from "react";
import CustomModal from "../Modal/Modal";
import ChooseMenuIcon from "./ChooseMenuIcon";

export default function ChooseMenuIconModal({
  open,
  handleClose,
  id,
  name,
  description,
  actualIcon,
  setIcon,
  saveFunction
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <ChooseMenuIcon
        closeModal={handleClose}
        id={id}
        name={name}
        description={description}
        actualIcon={actualIcon}
        setIcon={setIcon}
        saveFunction={saveFunction}
      />
    </CustomModal>
  );
}

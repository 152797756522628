import * as React from "react";
import Container from "@mui/material/Container";

export default function ProductListItemContainer({
  style,
  sx,
  id,
  children,
}) {
  return (
    <Container
      sx={sx}
      id={id}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor:
          style.backgroundColor ?? "var(--product-card-background-color)",
        padding: 20,
        borderLeft: "1px solid var(--border-color)",
        borderRight: "1px solid var(--border-color)",
        borderBottom: "1px solid var(--border-color)",
        //borderRadius: 6,
        ...style,
      }}
    >
      {children}
    </Container>
  );
}

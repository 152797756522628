import * as React from "react";

export default function Divider({ propsStyle }) {
  return (
    <div
      style={{
        height: 1,
        backgroundColor: "var(--border-color)",
        width: "100%",
        ...propsStyle,
      }}
    />
  );
}

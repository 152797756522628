import * as React from "react";
import ProductListItemContainer from "../common/ProductListItemContainer";
import "../index.css";
import CustomListButton from "../common/CustomListButton";
import CreateMenuModal from "../CreateMenu/CreateMenuModal";
// TODO doliaffiliate
// import { UserContext } from "../Context/UserContext";
import { QR_CODE_URL } from "../consts";

export default function TopMenuList() {
  // TODO doliaffiliate
  // const { currentMerchantId } = React.useContext(UserContext);
  const currentMerchantId = 0;
  const [openCreateMenu, setOpenCreateMenu] = React.useState(false);
  const handleOpenCreateMenu = () => setOpenCreateMenu(true);
  const handleCloseCreateMenu = () => setOpenCreateMenu(false);

  return (
    <>
      <ProductListItemContainer
        style={{
          marginBottom: 15,
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: "none",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "1px solid var(--border-color)",
        }}
      >
        <h2 style={{ margin: 0 }}>Menus</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <CustomListButton
            text={"Aperçu"}
            onClick={() =>
              window
                .open(`${QR_CODE_URL}/go/${currentMerchantId}`, "_blank")
                .focus()
            }
            primary={false}
          />
          <CustomListButton
            text={"Nouveau Menu"}
            onClick={handleOpenCreateMenu}
          />
        </div>
      </ProductListItemContainer>
      {openCreateMenu && (
        <CreateMenuModal
          open={openCreateMenu}
          handleClose={handleCloseCreateMenu}
        />
      )}
    </>
  );
}

import * as React from "react"

const WineIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 128 128"
    fill={color}
  >
    <path d="M39.1 1.7c-1.4 1.2-3.5 6.7-6.6 17.2-7.9 26.6-8.7 32.2-6.4 43.3 3.5 16.1 13.6 27 28.4 30.3l5 1.1.3 13.2.3 13.2h-6.5c-3.5 0-7.1.3-8 .6-2.1.8-2.1 6 0 6.8 2.7 1 35.4.7 37.5-.4 2.5-1.4 2.5-4.6 0-6-1.1-.5-4.9-1-8.6-1h-6.6l.3-13.2.3-13.2 4.2-.8c19.2-3.2 31.4-21 30.1-43.9-.5-9.6-9.7-42.2-13-46.5L87.9 0H64.5C43.8 0 41 .2 39.1 1.7zm48.3 15c1.6 5.4 3.1 10.6 3.3 11.5.5 1.7-1.2 1.8-25.9 1.8-14.6 0-26.7-.2-27-.5-.4-.5 2.6-11.8 5.7-21.3.3-.9 5.4-1.2 20.8-1.2h20.3l2.8 9.7zm6.5 22.6c2.9 7.5 2 21.5-1.8 30.2-7.7 17.4-34.2 22.2-49.1 8.8-6.6-5.9-9.2-12.4-9.8-23.8-.2-5.1.1-10.8.7-13.3l1.2-4.2H93l.9 2.3z" />
  </svg>
)
export default WineIcon

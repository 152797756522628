import React, { createContext, useState } from "react";
import { API_URL } from "../consts";
// TODO doliaffiliate
// import { UserContext } from "./UserContext";
// import { dataURLtoFile } from "../../services/uploadFileService";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  // TODO doliaffiliate
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  // TODO doliaffiliate
  const [changesModal, setChangesModal] = useState(false);
  const [settings, setSettings] = useState({
    appearance: {
      showRestaurantName: false,
      themeColor: "LIGHT_THEME",
    },
    wifi: {
      networkName: "",
      password: "",
    },
    socialNetworks: {
      instagram: "",
      facebook: "",
      x: "",
    },
    review: {
      display: true,
      link: "",
    },
    basic: {
      establishmentName: "",
      publicMenuURL: "",
      address: "",
      phoneNumber: "",
      message: "",
    },
  });

  const fetchSettings = async (currentMerchantId) => {
    if (!currentMerchantId) return;
    const response = await fetch(
      `${API_URL}api/marchants/${currentMerchantId}/informations`
    );
    const data = await response.json();
    if (data?.marchantId) {
      console.log("yes");
      setSettings(data);
    } else {
      console.log("no");
    }
  };

  const updateSettings = async (updatedSettings, currentMerchantId) => {
    console.log("in update settings", updatedSettings);
    if (!currentMerchantId) return;
    const formData = new FormData();

    formData.append(
      "basic",
      JSON.stringify({
        establishmentName:
          updatedSettings.basic?.establishmentName ??
          settings.basic?.establishmentName,
        publicMenuURL:
          updatedSettings.basic?.publicMenuURL ?? settings.basic?.publicMenuURL,
        address: updatedSettings.basic?.address ?? settings.basic?.address,
        phoneNumber:
          updatedSettings.basic?.phoneNumber ?? settings.basic?.phoneNumber,
        message: updatedSettings.basic?.message ?? settings.basic?.message,
      })
    );

    formData.append(
      "appearance",
      JSON.stringify({
        showRestaurantName:
          updatedSettings.appearance?.showRestaurantName ??
          settings.appearance?.showRestaurantName,
        themeColor:
          updatedSettings.appearance?.themeColor ??
          settings.appearance?.themeColor,
      })
    );

    formData.append(
      "wifi",
      JSON.stringify({
        networkName:
          updatedSettings.wifi?.networkName ?? settings.wifi?.networkName,
        password: updatedSettings.wifi?.password ?? settings.wifi?.password,
      })
    );

    formData.append(
      "socialNetworks",
      JSON.stringify({
        instagram:
          updatedSettings.socialNetworks?.instagram ??
          settings.socialNetworks?.instagram,
        facebook:
          updatedSettings.socialNetworks?.facebook ??
          settings.socialNetworks?.facebook,
        x: updatedSettings.socialNetworks?.x ?? settings.socialNetworks?.x,
      })
    );

    formData.append(
      "review",
      JSON.stringify({
        display: updatedSettings.review?.display ?? settings.review?.display,
        link: updatedSettings.review?.link ?? settings.review?.link,
      })
    );

    if (
      updatedSettings.customImage &&
      updatedSettings.customImage !== "none" &&
      updatedSettings.customImage.name &&
      updatedSettings.customImage.image
    ) {
      const fileToUpload = dataURLtoFile(
        updatedSettings.customImage.image,
        updatedSettings.customImage.name
      );
      formData.append("customImage", fileToUpload);
    } else if (updatedSettings.image) {
      formData.append("imageValue", updatedSettings.image.name);
    }

    try {
      const options = {
        method: "PUT",
        body: formData,
      };

      const response = await fetch(
        `${API_URL}api/marchants/${currentMerchantId}/informations`,
        options
      );

      const data = await response.json();
      setSettings(data);
      setChangesModal(true);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        changesModal,
        settings,
        updateSettings,
        setChangesModal,
        setSettings,
        fetchSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import { SettingsContext } from "../../Context/SettingsContext";
import { useTranslation } from "react-i18next";

function BasicInformation({campaignId}) {
  const { t } = useTranslation("global");
  const { settings, updateSettings } =
    React.useContext(SettingsContext);

  const [restaurantName, setRestaurantName] = React.useState(
    settings?.basic?.establishmentName
  );
  const [restaurantAddress, setRestaurantAddress] = React.useState(
    settings?.basic?.address
  );
  const [restaurantPhoneNumber, setRestaurantPhoneNumber] = React.useState(
    settings?.basic?.phoneNumber
  );
  const [message, setMessage] = React.useState(settings?.basic?.message);
  return (
    <div
      style={{
        width: "100%",
        paddingTop: 20,
        borderTop: "1px solid var(--border-color)",
      }}
    >
      <div
        style={{
          gap: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddItemInputContainer
          setter={setRestaurantName}
          value={restaurantName}
          name={t("textEstablishmentName")}
          titleFontSize={15}
        />
        <AddItemInputContainer
          setter={setRestaurantAddress}
          value={restaurantAddress}
          name={t("textAddress")}
          titleFontSize={15}
          placeholder={t("settingsEnterAddress")}
        />
        <AddItemInputContainer
          setter={setRestaurantPhoneNumber}
          value={restaurantPhoneNumber}
          name={t("settingsPhoneNumber")}
          titleFontSize={15}
          placeholder={t("settingsEnterPhoneNumber")}
        />
        <AddItemInputContainer
          setter={setMessage}
          value={message}
          name={t("settingsMessage")}
          titleFontSize={15}
          rows={4}
        />
      </div>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={async () => {
            updateSettings({
              basic: {
                establishmentName: restaurantName,
                address: restaurantAddress,
                phoneNumber: restaurantPhoneNumber,
                message: message,
              },
            }, campaignId);
          }}
        />
      </div>
    </div>
  );
}

export default BasicInformation;

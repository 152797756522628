import * as React from "react"
const CoffeeIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M182.2 4.5c-22.7 10.8-44.6 28.9-58.3 48.1l-5.2 7.3-7.1 1.6c-26.8 6-47.3 25.3-55.8 52.5l-2.3 7.5V227c0 97.6.1 106.2 1.8 114.3 5.6 27.5 18.3 51.2 37.9 70.9l9.7 9.8H57.2c-52.1 0-50.7-.2-54.9 8-2.9 5.6-2.9 8.4-.1 14 3.5 6.6 6.9 8 20.5 8H34v4.4c0 6.3 4.1 19.5 8.1 26.1 8 13.4 21.9 23.5 37.5 27.5 7.5 2 11.8 2 176.4 2s168.9 0 176.4-2c15.6-4 29.5-14.1 37.5-27.5 4-6.6 8.1-19.8 8.1-26.1V452h11.3c8.9 0 11.9-.4 14.7-1.8 3.8-2 8-8.9 8-13.2 0-4.3-4.2-11.2-8-13.2-3.3-1.7-8.4-1.8-97.5-1.8h-94l10.6-10.8c5.8-5.9 12.8-14.1 15.6-18.2 5.8-8.7 13.3-23.2 15.7-30.6l1.6-5 40-26.3c44.9-29.5 49.9-33.7 56.5-47.5 5.9-12.3 6.5-17 6.5-53.7 0-18-.5-35.1-1-38-2.1-11.3-11-24-21-30.1-9.5-5.7-12.3-6.1-44.1-6.6l-29.6-.4-.6-15.6c-1-24.4-5.1-37-16.8-51.7-9.6-12.2-24.4-21.7-39.7-25.6-5.5-1.4-11-1.9-20.6-1.9-22.2 0-34.7-2.1-49.5-8.2-6-2.5-9-4.5-14.2-9.7-7.6-7.6-10.5-13.9-11.5-25.2-.8-8.2-3.2-12.5-8.4-15.1-5.4-2.8-9.6-2.2-19.8 2.7zm5.2 40.7c8.5 17.6 22.6 29.4 44.4 37.2 12.9 4.6 25.8 6.6 47.5 7.4 19.9.8 20.3.8 27 3.9 11.5 5.5 21.4 16.9 24.5 28.5l1 3.8h-248l.7-3.3c.4-1.7 1.9-5.5 3.3-8.2C95.1 99.9 107 91.9 124.3 90c4.5-.5 9.1-1.7 10.8-2.8 1.6-1 5.5-5.9 8.8-10.9 7.7-11.5 19-23.6 30-31.8 4.7-3.6 8.9-6.5 9.3-6.5.4 0 2.2 3.3 4.2 7.2zm145.4 126 .3 14.8H83v-30l124.8.2 124.7.3.3 14.7zm87.2 16c8.8 4.5 8.5 3.2 8.5 42.6v34.7l-2.8 5.8c-1.6 3.3-4.7 7.2-7 9.2-2.3 1.9-15.8 11.1-29.9 20.5L363 316.9V185h26.3c25.3 0 26.4.1 30.7 2.2zM332.5 272c0 49.8-.2 56.4-1.8 64-8.4 39.2-34 68.2-70.8 80.4-15.4 5.1-23.9 5.9-57.9 5.4-27.8-.5-30.1-.6-39.2-3.1-19.3-5.1-35.6-14.7-49.5-29-13.5-13.9-22-28.8-27.1-47.5l-2.6-9.7-.4-58.3-.3-58.2 124.8.2 124.8.3V272zM448 454.8c0 8.5-7.3 19.2-16.4 24l-5.1 2.7h-341l-5.1-2.7c-9.1-4.8-16.4-15.5-16.4-24V452h384v2.8z" />
  </svg>
)
export default CoffeeIcon

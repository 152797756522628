import * as React from "react"
const CakeIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M249.2 28.7c-6.7 3.3-7.7 6.1-8 22.5l-.3 14.6-3.7.7c-17.7 3.3-38.6 20.3-47.7 38.8-5.4 11.2-7.4 18.3-8.2 30.5l-.6 9.3L92.9 189c-73.6 36.9-88.1 44.5-90.1 47.2l-2.3 3.3-.3 116.8C0 471.8 0 473.1 2 477c1.3 2.6 3.4 4.7 6 6 3.9 2 5.4 2 247.6 2h243.8l4.2-2.1c3.1-1.5 4.8-3.2 6.3-6.3 2.1-4.2 2.1-5.1 2.1-126.2 0-77-.4-125.2-1-130.9-7.9-68.9-53.8-125.8-118.6-147.1-12.2-3.9-27.7-7.1-39.9-8l-9-.6-19 9.5-19 9.4-4.5-3.5c-5.6-4.3-18.4-10.6-25-12.3l-5-1.3v-13c0-11.1-.3-13.6-2.1-17.3-3.6-7.3-12.3-10.3-19.7-6.6zm16 66.4c22.7 4.9 38.4 27.7 34.8 50.6-4 24.6-27.1 41.6-51 37.5-24.2-4.1-41.1-27.3-37.2-50.8 4.3-25.7 28.5-42.6 53.4-37.3zM366 96.6c32.9 5.8 66.3 26.9 87.6 55.4 15 20.1 25.1 46.2 27.8 72.2l.6 5.8H352.7c-123.9 0-129.4.1-132.7 1.9-1.9 1-4.5 3.6-5.7 5.7-2.2 3.9-2.3 4.3-2.3 44.4s-.1 40.5-2.3 44.4c-5.6 9.8-17.9 9.9-25.1.2-2-2.6-2.1-4.3-2.6-45-.5-46.3-.4-45.2-6.7-49.3-2.5-1.6-7-1.8-49.3-2.3l-46.5-.5 55.2-27.7 55.2-27.7 2.6 4.5c3.9 6.7 14.8 17.8 22.4 22.8 13.4 8.9 24.5 12.1 41.6 12 21.4-.1 37-6.6 52-21.8 9.1-9.3 13.7-16.4 17.6-27.6 6.4-18.1 5.9-35.7-1.6-55.2-.6-1.4 1.7-2.9 12.2-8.2 14.1-7.1 12.4-6.9 29.3-4zM152 282v22H30v-44h122v22zm329.8.2-.3 22.3-119.7.3-119.8.2v-45h240l-.2 22.2zm-324.3 57.4c8.5 17.3 30.4 27.7 49.4 23.3 11.8-2.7 25.1-13 30-23.2l2.3-4.7H482v59H30v-60h124.8l2.7 5.6zM482 439.5V455H30v-31h452v15.5z" />
    <path d="M398.1 156.2c-5.5 3.7-7.6 7.3-7.6 12.8 0 7.7 5.7 13.9 13.6 14.7 11.5 1.2 19.5-11.1 14.1-21.6-2.4-4.8-6.2-7.3-11.9-7.8-3.9-.4-5.5 0-8.2 1.9z" />
  </svg>
)
export default CakeIcon

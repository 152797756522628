import * as React from "react";
import CustomModal from "../Modal/Modal";
import CreateCategorie from "./CreateCategorie";

export default function CreateCategorieModal({ open, handleClose, menuId }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <CreateCategorie closeModal={handleClose} menuId={menuId} />
    </CustomModal>
  );
}

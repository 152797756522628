import * as React from "react";
import CustomModal from "../Modal/Modal";
import CreateMenu from "./CreateMenu";

export default function CreateMenuModal({ open, handleClose }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <CreateMenu closeModal={handleClose}/>
    </CustomModal>
  );
}

import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import { InputAdornment } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { SettingsContext } from "../../Context/SettingsContext";
import { useTranslation } from "react-i18next";

function SocialNetworks({campaignId}) {
  const { t } = useTranslation("global");
  const { settings, updateSettings } = React.useContext(SettingsContext);

  const [instagram, setInstagram] = React.useState(
    settings?.socialNetworks?.instagram
  );
  const [facebook, setFacebook] = React.useState(
    settings?.socialNetworks?.facebook
  );
  const [twitter, setTwitter] = React.useState(settings?.socialNetworks?.x);

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 20,
        borderTop: "1px solid var(--border-color)",
      }}
    >
      <div
        style={{
          gap: 15,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddItemInputContainer
          name={t("settingsInstagram")}
          setter={setInstagram}
          value={instagram}
          titleFontSize={15}
          startAdornment={
            <InputAdornment position="end">
              <AlternateEmailIcon
                fontSize={"10px"}
                style={{ marginLeft: -10, marginRight: 5 }}
              />
            </InputAdornment>
          }
        />
        <AddItemInputContainer
          name={t("settingsFacebook")}
          setter={setFacebook}
          value={facebook}
          titleFontSize={15}
          startAdornment={
            <InputAdornment position="end">
              <AlternateEmailIcon
                fontSize={"10px"}
                style={{ marginLeft: -10, marginRight: 5 }}
              />
            </InputAdornment>
          }
        />
        <AddItemInputContainer
          name={t("settingsTwitter")}
          setter={setTwitter}
          value={twitter}
          titleFontSize={15}
          startAdornment={
            <InputAdornment position="end">
              <AlternateEmailIcon
                fontSize={"10px"}
                style={{ marginLeft: -10, marginRight: 5 }}
              />
            </InputAdornment>
          }
        />
      </div>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={() => {
            updateSettings({
              socialNetworks: {
                instagram,
                facebook,
                x: twitter,
              },
            }, campaignId);
          }}
        />
      </div>
    </div>
  );
}

export default SocialNetworks;

import * as React from "react"
const Cocktail2Icon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 128 128"
    fill={color}
  >
    <path d="M81.1 1.9c-.6 1-1.5 4-2.1 6.7-.6 2.7-1.5 6.6-2.1 8.7l-1 3.8-11.6-.3-11.6-.3-1.4-4C45.7-.3 22.2-4.7 11 8.8c-3.9 4.7-6.4 13.1-5.6 18.4 1.4 8.5 8.1 16.8 15.7 19.3l3.4 1.2.5 35.6c.7 48.1-2.3 44.7 39 44.7 24.2 0 27.9-.2 30.9-1.8 6.2-3.1 7.6-6.1 7.9-17.7l.4-10.3 5.4-.4c6.3-.5 10.1-2.9 12.6-7.9 2.5-4.8 2.6-39.8.2-45.9-2.1-5.3-6.6-8-13.3-8H103v-6.3c0-3.6-.5-6.8-1.2-7.5-.7-.7-4.4-1.2-9.5-1.2-4.6 0-8.3-.3-8.3-.8.1-.4.7-3.2 1.4-6.2l1.2-5.5 8-.5c8.6-.5 10.4-1.2 10.4-3.9C105 .7 102.9 0 92.3 0c-9 0-10.4.2-11.2 1.9zM33.7 8.8c1 .6.7 1.7-1.2 4.3-1.4 1.9-3 3.4-3.5 3.4s-2.1-1.5-3.5-3.4C22.6 9.1 23.4 8 29 8c1.9 0 4.1.4 4.7.8zM22 21.6c0 .9-6.1 3.4-8.4 3.4-1.1 0-.6-4 1-7.8l1.5-3.7L19 17c1.6 1.9 3 4 3 4.6zm21.4-4.7c.9 1.6 1.2 3.1.9 3.5-.8.8-7.3.8-7.3 0s4-6.4 4.5-6.4c.3 0 1.1 1.3 1.9 2.9zM74 29.2c0 .7-.6 3.5-1.3 6.3l-1.3 5-19.2.3L33 41V28h20.5c15.7 0 20.5.3 20.5 1.2zm21 5.3V41h-7.3c-4.1 0-7.6-.3-7.9-.6-.3-.3-.2-2 .2-3.7.4-1.8 1.1-4.4 1.4-6 .6-2.6.9-2.7 7.1-2.7H95v6.5zM25 35c0 2.7-.3 5-.8 5-1.6 0-8.2-5.1-8.2-6.4 0-1.2 4.5-3.2 7.8-3.5.8-.1 1.2 1.5 1.2 4.9zm87.8 9.6c2.2 1.5 2.2 2 2.2 21.7 0 11.1-.4 20.7-.8 21.4-1 1.5-5.7 3.3-8.8 3.3H103V43h3.8c2.1 0 4.8.7 6 1.6zM95 82.2c0 18.9-.4 34.8-.8 35.5-1.7 2.7-7 3.3-30.2 3.3s-28.5-.6-30.2-3.3c-.4-.7-.8-16.6-.8-35.5V48h62v34.2z" />
    <path d="M46.7 62.8C43 66.5 40 70 40 70.6 40 72.4 53.5 85 55.6 85 58.2 85 70 73.1 70 70.4 70 67.8 58.1 56 55.4 56c-1.1 0-4.8 2.9-8.7 6.8zm11.6 5 2.7 2.8-3 2.9-3 2.9-2.5-2.4c-3.1-2.9-3.1-3.7-.3-6.7 2.7-2.9 2.9-2.9 6.1.5zM64.7 89.8C61 93.5 58 97.2 58 98.1 58 100 71.1 113 73.1 113 74.9 113 88 99.8 88 98c0-1.8-13.1-15-14.9-15-.9 0-4.7 3-8.4 6.8zM75.4 101l-3 3-2.9-3-2.9-3 2.9-3 2.9-3 3 3 3.1 3-3.1 3z" />
  </svg>
)
export default Cocktail2Icon

import * as React from "react"
const PizzaIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M173 1.5c-11.4 2.6-20.5 7.8-30 17.4-13.2 13.3-18.5 25.9-18.4 43.6.2 14.1 4.4 26.8 12.6 37.6l3.4 4.4L70.7 298C32.3 404.4.6 493.3.3 495.6-.8 503.7 6.6 512 14.8 512c1.6 0 15.4-4.5 30.8-10.1 15.3-5.6 31.5-11.4 35.9-12.9 4.4-1.5 35-12.5 68-24.5 33-11.9 91.1-32.8 129-46.5 38-13.7 82.5-29.8 99.1-35.7l30.1-10.9 3.3 2.8c5 4.1 15.7 9.4 23 11.3 7.9 2.1 22.5 2.4 30 .6 10.9-2.6 19.8-7.9 29-17.1 13.7-13.7 19.8-28.9 18.7-46.7-.8-10.9-4.2-20.7-13.4-37.7-50.7-94.2-126.2-177.4-216-237.9-27-18.2-63.8-39-76.7-43.4C196.5.2 182.3-.6 173 1.5zm25.1 30.9c2 .8 9.2 4.5 16 8.2 100.2 53.8 190.4 141.2 247.8 240.2 13.3 22.9 19.2 35.6 19.8 42 1.4 15.3-9 29.7-24.4 33.7-10.4 2.8-22.6-.6-29.8-8.2-2-2.1-7.3-10.6-11.7-18.8-59-110.4-124.9-175.6-238.1-235.9-5.9-3.2-12.4-7.4-14.4-9.3-4.3-4.4-7.9-12-8.7-18.8-1.8-13.9 8.4-29.3 22.4-34 5.4-1.8 15.8-1.3 21.1.9zm-16.5 97.5c84.8 47.5 142 102.4 190.1 182.6 11.4 19 18.7 32.5 18 33.2-.5.4-45.3 16.8-51.3 18.7-1.7.5-2.7-.4-5.9-5-14-20.2-40.2-32.7-65.5-31.1-16.7 1.1-29.9 6.1-42.7 16.1-19.4 15.2-31.1 41.3-28.9 64.6.6 6.7.6 6.8-2.2 7.8-1.5.5-14.2 5.1-28.2 10.2s-27.2 9.8-29.2 10.6l-3.8 1.3-3.5-7.5c-9.8-21.3-26-37.7-46.9-47.4l-8.5-4 1.4-3.8c.7-2 4.3-12 8-22.2s7.1-19.7 7.7-21.3c1-2.7 1.1-2.7 9.6-2.7 11.4 0 19.6-2.1 31.1-7.6 7.8-3.8 10.9-6 18.1-13.2 12.8-12.8 19.4-25.5 22-42.7 2.2-14.7-.1-28.2-7.7-43.5-3.9-8.1-6-10.9-14-18.9l-9.4-9.4 12.7-35.1c7-19.3 12.9-35.7 13.1-36.4.3-.6.8-1.2 1.2-1.2.3 0 7 3.5 14.7 7.9zm-49.2 99.6c4 5.2 7.5 13.5 8.8 20.5 3.8 21.9-13.3 46.2-35 49.6l-4.3.7 3.9-10.9c18.7-52.2 22.8-63.4 23.3-63.4.4 0 1.9 1.6 3.3 3.5zm150.1 130c7.6 2 14.5 5.8 20.3 11.3 3.8 3.4 4.4 4.5 3.2 5.1-3.3 1.9-80.3 29.1-80.8 28.6-.3-.2 0-3.5.6-7.2 4.5-26.9 30.8-44.5 56.7-37.8zM72.4 412.7c10 5.3 22 17.4 26.9 27.2 2 4 3.7 7.9 3.7 8.5 0 .9-62 23.9-62.6 23.3-.1-.1 4.8-14.3 10.9-31.4 7.2-20 11.7-31.3 12.6-31.3.7 0 4.5 1.7 8.5 3.7z" />
    <path d="M186 172.6c-6.3 4-8.8 12.8-5.5 19.7 4.8 10.1 21 10.4 26.1.5 3.6-6.9 1.3-16.1-4.9-19.8-3.9-2.4-12.1-2.6-15.7-.4zM216.2 260.7c-6.4 1.2-12.2 11.5-10.2 18.3 4.7 16.5 29 13.2 29-4 0-9.8-8.5-16.3-18.8-14.3zM307.4 279.4c-7.3 3.2-10.4 13.2-6.3 20.5 7.2 12.9 26.1 8.7 27.6-6 1-11.1-10.7-19.1-21.3-14.5zM140.2 354.7c-6.4 1.2-12.2 11.5-10.2 18.3 4.7 16.5 29 13.2 29-4 0-9.8-8.5-16.3-18.8-14.3z" />
  </svg>
)
export default PizzaIcon

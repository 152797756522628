import React, { useState } from "react";
import MenusList from "./imported/MenusList/MenusList";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomListButton from "./imported/common/CustomListButton";
import Settings from "./imported/Settings/Settings";
import { SettingsContext } from "./imported/Context/SettingsContext";

function CreateCampaign() {
  const { campaignName, campaignId } = useParams();
  const navigate = useNavigate();
  const [isMenuSelected, setIsMenuSelected] = useState(true);
  const { settings, fetchSettings } = React.useContext(SettingsContext);
  React.useEffect(() => {
      fetchSettings(campaignId);
      console.log("fetching settings");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: 64,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 0, cursor: "pointer" }}>
            <div
              onClick={() => navigate(`/campaign`)}
              variant="outlined"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
              }}
            >
              <ArrowBackIcon sx={{ mr: 1, height: 20 }} />
              Retour aux campagnes
            </div>
          </Box>
        </div>
        <h1>Campagne {campaignName}</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <CustomListButton
            text={"Menus"}
            big={true}
            primary={false}
            type={"submit"}
            backgroundColorProps={
              isMenuSelected
                ? "var(--create-product-secondary-button-hover-color)"
                : ""
            }
            onClick={() => setIsMenuSelected(true)}
          />
          <CustomListButton
            text={"Informations"}
            big={true}
            primary={false}
            type={"submit"}
            backgroundColorProps={
              !isMenuSelected
                ? "var(--create-product-secondary-button-hover-color)"
                : ""
            }
            onClick={() => setIsMenuSelected(false)}
          />
        </div>
      </Container>
      {isMenuSelected && <MenusList />}
      {!isMenuSelected && <Settings />}
    </>
  );
}

export default CreateCampaign;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import AddItemInputContainer from "./imported/common/AddItemInputContainer";
import CustomListButton from "./imported/common/CustomListButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import Divider from "./imported/common/Divider";
import { API_URL } from "./imported/consts";

function CreatePromoCode() {
  const { campaignName, campaignId } = useParams();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}promo-codes`,
        { code: code.toLocaleUpperCase(), campaignId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(response.data.message);
      if (response.data.alreadyExists === true) {
        //alert("Ce code existe déjà");
        setError("Ce code promo existe déjà");
        return;
      } else {
        navigate(`/campaign`);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const handleCreatePromoCodeButton = () => {
    document.getElementById("create-promo-code").click();
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: 64,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 0, cursor: "pointer" }}>
            <div
              onClick={() =>
                navigate(`/campaign/${campaignId}/${campaignName}`)
              }
              variant="outlined"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
              }}
            >
              <ArrowBackIcon sx={{ mr: 1, height: 20 }} />
              Retour vers la campagne {campaignName}
            </div>
          </Box>
        </div>

        <h1>Création du code promotionnel pour {campaignName}</h1>
        <Divider propsStyle={{ marginBottom: 20 }} />

        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 20,
          }}
        >
          {error && (
            <div
              style={{
                color: "red",
                fontSize: 16,
              }}
            >
              {error}
            </div>
          )}
          <AddItemInputContainer
            name={"Nom du Code Promotionnel"}
            setter={setCode}
            value={code}
            required={true}
            style={{
              maxWidth: 500,
              marginRight: "auto",
              marginLeft: "0px",
              padding: "0px",
            }}
          />

          <CustomListButton
            text={"Sauvegarder"}
            onClick={() => handleCreatePromoCodeButton()}
            big={true}
            type={"submit"}
            minWidth={"100%"}
          />

          <button
            style={{ display: "none" }}
            id={"create-promo-code"}
            type="submit"
          ></button>
        </form>
      </Container>
    </>
  );
}

export default CreatePromoCode;

import * as React from "react";

const ChickenIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M409.8 60.9c-19.9 6.4-32.8 24-32.8 44.6 0 8.9 1.9 16.8 5.5 23.1l2.4 4-14.3 14.3-14.2 14.2-9.9-4.7c-12.4-5.8-32.8-12.5-46-15.3-40-8.2-84.1-2.3-136.5 18.2-38.9 15.2-74.5 37.2-98 60.7-12.4 12.3-20.9 23.8-27.6 37.5C23 289 25 319.6 44.6 347.7l4.3 6.3H30.2c-15.8 0-19.2.3-22.1 1.8-5.3 2.6-7.6 6.5-7.6 12.5 0 5 .9 6.8 21.4 41.2 11.8 19.8 22.9 37.6 24.8 39.5l3.3 3.5h381.9l3-2.9c1.7-1.6 12.8-19.3 24.7-39.4 23.4-39.4 24.3-41.3 20.7-48.2-1-1.9-3.3-4.5-5.2-5.7-3.2-2.2-4.4-2.3-21.3-2.3H436l3.1-6.3c11.9-23.5 14.6-53 7.4-80.5-5.5-20.8-11.7-32.7-30-57.8-.7-1.1 1.7-4.1 10.1-12.5l11.1-11.1 5.1 2.7c6.7 3.5 14.4 5.5 21.2 5.5 30.3 0 52.9-27.4 46.7-56.6-.9-3.9-2.7-9.3-4-12-5.2-10.3-15.9-19.4-26.7-22.9-9.8-3.2-9.7-3.1-10.3-7.2-1.1-6.4-6.7-16.4-12.7-22.4-5.8-5.8-12.8-10-20.9-12.5-6.5-1.9-19.4-1.7-26.3.5zM432 90c2.2 1.1 5.2 3.8 6.6 6 2.7 4.1 3 6.1 2.4 18.2-.3 6.9 2.3 11.7 7.6 14.3 2.9 1.3 5.1 1.5 11.8 1 7.2-.6 8.8-.4 12.3 1.4 13.4 6.8 13.3 25.6-.1 32.1-8 3.8-15.8 1.7-23.4-6.4-4.9-5.2-7.3-6.1-13.9-5.3-4.4.5-5.3 1.2-31 26.8-18.1 18.2-26.6 27.4-27.2 29.5-.5 1.7-.5 5.3 0 8 3 18.7-1.6 45.9-12.6 73.2-1.9 4.7-3.5 10.1-3.5 12 0 14.6 19.1 19.7 26.7 7.1 4.9-8 15-39.8 17.3-54.7.7-3.9 1.4-7.2 1.6-7.2 1.8 0 10.9 23 13 33 2.3 11 2.1 29.4-.5 39.5-2.6 9.8-8.4 22.1-14.1 29.7l-4.3 5.8H91.1l-5.9-4.9c-17.9-14.9-27.5-32.6-27.6-50.6 0-9.9 1.6-16.7 6.4-27 21.3-45.9 103.8-93.6 178.9-103.5 10.7-1.4 34-1.4 42.3 0 12.1 2 12.1 1.9 2.8 5-39.2 12.9-79.9 37.2-98.3 58.7-16.7 19.4-26.9 47.1-25.5 68.8.4 6 .7 6.9 4 10.2 3.1 3.1 4.5 3.7 9 4 4.1.3 6-.1 8.5-1.6 5.2-3.3 6.5-6.3 7.4-16.9 3-32.5 19.4-53.6 59.4-75.9 35.8-19.9 73.2-30.3 98.5-27.2 8 1 10.5.9 13.2-.1 4-1.6 48.8-45.6 52.9-51.9 4.9-7.6 3.4-14.2-4.8-21.4-4.3-3.7-6.3-8.1-6.3-13.6 0-13.7 13.9-22.3 26-16.1zm-2.3 313.5L417.5 424H241l-176.5-.1-11.7-19.7C46.3 393.3 41 384.1 41 383.7c0-.4 90.2-.7 200.4-.7h200.4l-12.1 20.5z" />
  </svg>
);
export default ChickenIcon;

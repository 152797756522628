import { Container } from "@mui/material";
import * as React from "react";
import CustomListButton from "../common/CustomListButton";
import CreateMenuModal from "../CreateMenu/CreateMenuModal";

export default function NoMenuCard() {
  const [openCreateMenu, setOpenCreateMenu] = React.useState(false);
  const handleOpenCreateMenu = () => setOpenCreateMenu(true);
  const handleCloseCreateMenu = () => setOpenCreateMenu(false);

  return (
    <>
      <Container
        style={{
          borderRadius: 5,
        }}
        sx={{
          margin: 0,
          padding: "0px !important",
          backgroundColor: "var(--product-card-background-color)",
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 15px",
            gap: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 5,
              textAlign: "center",
            }}
          >
            <div
              style={{
                color: "var(--primary-text-color)",
                fontWeight: "500",
                fontSize: 16,
              }}
            >
              Vous n'avez pas encore de menu..
            </div>
            <div
              style={{
                color: "var(--category-secondary-text-color)",
                fontSize: 15,
              }}
            >
              Créez un nouveau menu pour commencer à ajouter des produits
            </div>
          </div>
          <div style={{
            display:'flex',
            justifyContent:'center',
            width:'100%',
          }}>
          <div
            style={{
              height: 38,
              width: 200,
            display:'flex',
            justifyContent:'center',
            }}
          >
            <CustomListButton
              text={"Nouveau Menu"}
              onClick={handleOpenCreateMenu}
            />
          </div></div>
        </Container>
      </Container>
      {openCreateMenu && (
        <CreateMenuModal
          open={openCreateMenu}
          handleClose={handleCloseCreateMenu}
        />
      )}
    </>
  );
}

import * as React from "react"
const BeerIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 128 128"
    fill={color}
  >
    <path d="M18.2 1.2c-.7.7-1.2 4-1.2 8 0 3.9-.4 6.8-1 6.8-.5 0-1 1.1-1 2.4 0 1.4.5 2.8 1 3.1.6.4 1 5.7 1 12.7v12l-3.4 3.9c-1.9 2.2-3.9 6-4.5 8.4-1.4 5.8-1.5 64.9-.1 67.6 1 1.8 2.3 1.9 20.3 1.9 13.7 0 19.6-.3 20.5-1.2.9-.9 1.2-9.8 1.2-34 0-35.9-.1-36.7-6-43.4-3-3.4-3-3.6-3-15.1 0-9.4.3-12 1.6-13.4 1.5-1.7 1.5-2 0-4.3-1-1.6-1.6-4.7-1.6-8.4 0-3.2-.5-6.3-1.2-7-1.6-1.6-21-1.6-22.6 0zM35 11.5V15H25V8h10v3.5zM34.8 36c.3 13.3.3 13.5 3.1 16.5 1.6 1.6 3.4 4 4 5.2 1.1 2.2 1.6 12.3.6 12.3-.3 0-1.7-.7-3-1.6-1.3-.9-5-1.8-8.2-2.1-4.8-.4-6.6 0-10.5 2L16 70.7V65c0-5.2.4-6.1 4.5-11.4l4.5-5.8V21.9l4.8.3 4.7.3.3 13.5zm4.3 41.9C42.8 81.6 43 82 43 88.3c0 6-.3 7-3.1 10.1-8.9 10.2-23.9 4-23.9-9.9 0-9 5.4-14.5 14.4-14.5 4.3 0 5.3.4 8.7 3.9zm-19 30.8c5.4 2.7 12.8 2.9 18.3.5l4.6-2.1V121H16v-7c0-3.9.2-7 .3-7 .2 0 1.9.8 3.8 1.7zM61.3.7c-1.9.7-1.7 3.8 3.5 51L69.6 95l-1.3 15.1c-1 11.2-1 15.6-.2 16.6 1.7 2 41.2 1.9 42.8-.1.9-1.1.9-5.3 0-17.8l-1.1-16.3 4.9-44.5c3.2-28.5 4.7-45.1 4.1-46.3C118 .1 115.7 0 90.2.1 75 .1 61.9.4 61.3.7zm48.6 22c-1 8.2-1.8 14.9-1.8 15-.1.2-8.4.3-18.6.3-10.2 0-18.5-.1-18.5-.3 0-.1-.7-5.8-1.5-12.7-.8-6.9-1.5-13.5-1.5-14.8V8h43.6l-1.7 14.7zM107 48.5c0 1.3-1.1 12.4-2.5 24.7-1.6 14-2.3 24.3-1.9 27.6l.6 5.3-13.3-.3-13.4-.3.3-6c.2-3.3-.8-16-2.2-28.3C73.1 58.9 72 48.3 72 47.5c0-1.3 2.9-1.5 17.5-1.5H107v2.5zm-3 68v3.5H75v-2.8c0-1.6.3-3.2.7-3.5.3-.4 6.9-.7 14.5-.7H104v3.5z" />
    <path d="M92.2 63.2c-3 3 .3 7.7 4 5.7 3-1.6 1.6-6.9-1.7-6.9-.6 0-1.6.5-2.3 1.2zM82.3 77.6c-.8 2.2 1 4.9 3.2 4.9 1 0 2.2-.7 2.8-1.6 1.5-2.4.1-4.9-2.8-4.9-1.5 0-2.8.7-3.2 1.6zM87.2 91.2c-3 3 .3 7.7 4 5.7 2.1-1.1 2.4-3.9.6-5.7-1.5-1.5-3.1-1.5-4.6 0z" />
  </svg>
)
export default BeerIcon

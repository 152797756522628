import * as React from "react"

const CocktailIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M344 1.1c-4.1 1.6-7.7 5.8-10 11.4-2.1 5.3-2.2 5.3-7.3 6-23.7 3.1-45 24.8-51.9 52.8l-2.1 8.7-103.2.2-103.2.3-4.1 2.8c-6.6 4.6-8.8 12.8-5.7 20.5 1.1 2.6 28.5 30.7 92 94.2l90.5 90.5v129L205.4 451c-18.4 18.4-34.4 35.2-35.5 37.2-4 7.7-.8 17.4 7 21.5l4.4 2.3h149.4l4.4-2.3c7.8-4.1 11-13.8 7-21.5-1.1-2-17.1-18.8-35.5-37.2L273 417.5v-129l90.5-90.5c63.5-63.5 90.9-91.6 92-94.2 3.1-7.7.9-15.9-5.7-20.5l-4.1-2.8-34.6-.3-34.6-.3V69.2c0-13.3-2.6-22.2-9.1-31.9l-4.6-6.8 2.2-6.3c2.5-7.1 2.2-11.7-1.3-17-3.9-5.8-13.1-8.7-19.7-6.1zm-8 51.4c4.7 2.5 7.3 8.6 7.3 17.6 0 4.2-.3 8.2-.7 8.8-.5.7-6.4 1.1-18.3 1.1h-17.5l.6-2.8c2.3-9.5 10-19.7 17.8-23.6 6.1-3 6.9-3.1 10.8-1.1zm-56.4 64.7c.9 1.8 2.9 4.8 4.4 6.6l2.8 3.4-3.6 8.9-3.6 8.9H143.5L128 129.5 112.5 114h165.4l1.7 3.2zM384 129.5 368.5 145h-26.2c-21.7 0-26.3-.2-26.3-1.4 0-2.2 2.1-3.3 7.5-3.9 11.6-1.3 27.1-10.3 35-20.4l4-5.2H381l18.5-.1-15.5 15.5zm-119 49.6c0 1.5-23.8 60.1-24.8 61.1-.4.4-14.9-13.4-32.2-30.7L176.5 178h44.3c32.6 0 44.2.3 44.2 1.1zm70-.8c0 .1-12.6 12.8-28.1 28.2-26.7 26.7-27.9 27.8-26.1 23 2.8-7.2 20.3-49.9 20.9-50.8.4-.6 33.3-1.1 33.3-.4zm-63 285.2 15.5 15.5h-63l15.5-15.5c8.5-8.5 15.7-15.5 16-15.5.3 0 7.5 7 16 15.5z" />
  </svg>
)
export default CocktailIcon

import * as React from "react";
import CustomModal from "../Modal/Modal";
import EditMenu from "./EditMenu";

export default function EditMenuModal({
  open,
  handleClose,
  id,
  name,
  description,
  icon,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <EditMenu
        closeModal={handleClose}
        actualName={name}
        actualDescription={description}
        actualIcon={icon}
        id={id}
      />
    </CustomModal>
  );
}

import React from "react";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import AddItemInputContainer from "../imported/common/AddItemInputContainer";
import CustomListButton from "../imported/common/CustomListButton";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MenusContext } from "../imported/Context/MenusContext";
import { API_URL } from "../imported/consts";

function NewCampaign({ closeModal }) {
  const { setCurrentMerchantId } = React.useContext(MenusContext);
  const [campaignName, setCampaignName] = React.useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleCreateCampaign = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}api/marchants/`,
        { campaignName: campaignName, userId: user.id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      setCurrentMerchantId(response.data._id);
      navigate(`/campaign/${response.data._id}/${response.data.campaignName}`);
      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const handleCreateCampaignButton = () => {
    document.getElementById("create-campaign-button").click();
  };

  return (
    <>
      <Container
        style={{
          borderBottom: "1px solid var(--border-color)",
          paddingBottom: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: 20,
          }}
        >
          Nouvelle Campagne
        </div>
        <div>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </Container>
      <form onSubmit={handleCreateCampaign}>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 20,
            marginTop: 20,
          }}
        >
          <AddItemInputContainer
            name={"Nom de la campagne"}
            setter={setCampaignName}
            required={true}
          />
        </Container>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            padding: 24,
            backgroundColor: "var(--default-background-color)",
          }}
        >
          <button
            style={{ display: "none" }}
            id={"create-campaign-button"}
            type="submit"
          >
            Create Campaign
          </button>
          <CustomListButton
            text={"Créer"}
            onClick={() => handleCreateCampaignButton()}
            big={true}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default NewCampaign;

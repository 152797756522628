import React from "react";
import Container from "@mui/material/Container";
import ProductCard from "../common/ProductCard";
import ProductListFooter from "./ProductListFooter";
import TooltipMenu from "../common/TooltipMenu";
import EditProductModal from "../EditProduct/EditProductModal";
import EditCategorieModal from "../EditCategorie/EditCategorieModal";
import { CategoriesContext } from "../Context/CategoriesContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { iconsList } from "../iconsList";
import { imagesList } from "../imagesList";
import EditMenuModal from "../EditMenu/EditMenuModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";

function ProductsList() {
  const { menuId, campaignName, campaignId } = useParams();
  const navigate = useNavigate();
  const {
    categories,
    menuInformation,
    deleteCategory,
    moveCategory,
    movePlat,
    deletePlat,
    fetchCategories,
  } = React.useContext(CategoriesContext);
  const [openEditProduct, setOpenEditProduct] = React.useState(false);
  const [productToEdit, setProductToEdit] = React.useState(null);
  const [openEditCategorie, setOpenEditCategorie] = React.useState(false);
  const [categorieToEdit, setCategorieToEdit] = React.useState(null);
  const [openEditMenu, setOpenEditMenu] = React.useState(false);

  {
    /**
     * TODO(chris):
     * Map each product image to not do redondant code
     */
  }

  const handleOpenEditMenu = (product) => {
    setOpenEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    setOpenEditMenu(false);
  };

  const handleOpenEditProduct = (product) => {
    setProductToEdit(product);
    setOpenEditProduct(true);
  };

  const handleCloseEditProduct = () => {
    setProductToEdit(null);
    setOpenEditProduct(false);
  };

  const handleOpenEditCategorie = (product) => {
    setCategorieToEdit(product);
    setOpenEditCategorie(true);
  };

  const handleCloseEditCategorie = () => {
    setCategorieToEdit(null);
    setOpenEditCategorie(false);
  };

  const categorySettings = (categoryId, index) => {
    const isFirst = index === 0;
    const isLast = index === categories.length - 1;

    return [
      {
        name: "Editer",
        action: () => handleOpenEditCategorie(categories[index]),
      },
      { name: "Supprimer", action: () => deleteCategory(categoryId) },
      {
        name: "Déplacer en Haut",
        action: () => moveCategory(categoryId, "moveUp"),
        hidden: isFirst,
      },
      {
        name: "Déplacer en Bas",
        action: () => moveCategory(categoryId, "moveDown"),
        hidden: isLast,
      },
    ];
  };

  const platSettings = (articleId, index, plats) => {
    const isFirst = index === 0;
    const isLast = index === plats.length - 1;

    return [
      { name: "Editer", action: () => handleOpenEditProduct(plats[index]) },
      { name: "Supprimer", action: () => deletePlat(articleId) },
      {
        name: "Déplacer en Haut",
        action: () => movePlat(articleId, "moveUp"),
        hidden: isFirst,
      },
      {
        name: "Déplacer en Bas",
        action: () => movePlat(articleId, "moveDown"),
        hidden: isLast,
      },
    ];
  };

  React.useEffect(() => {
    if (menuId) {
      fetchCategories(menuId);
    }
  }, [menuId, openEditMenu]);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: 64,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 0, cursor: "pointer" }}>
            <div
              onClick={() =>
                navigate(`/campaign/${campaignId}/${campaignName}`)
              }
              variant="outlined"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
              }}
            >
              <ArrowBackIcon sx={{ mr: 1, height: 20 }} />
              Retour vers la campagne {campaignName}
            </div>
          </Box>
        </div>
      </Container>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            marginBottom: 25,
            cursor: "pointer",
          }}
          onClick={() => handleOpenEditMenu()}
        >
          {menuInformation?.icon && (
            <div
              style={{
                fontSize: 18,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 6,
                height: 42,
                width: 42,
                minWidth: 32,
                backgroundColor: "var(--white-svg-background)",
              }}
            >
              {
                iconsList.find(
                  (icon) => icon.name === menuInformation?.icon ?? "fork"
                ).icon
              }
            </div>
          )}
          <h1>{menuInformation?.name ?? ""}</h1>
        </div>
        {categories &&
          categories
            .sort((a, b) => a.order - b.order)
            .map((category, categoryIndex) => (
              <div key={category._id} style={{ marginBottom: 30 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 15,
                    flex: 2,
                  }}
                >
                  <div
                    onClick={() =>
                      handleOpenEditCategorie(categories[categoryIndex])
                    }
                    style={{
                      cursor: "pointer",
                      flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflowWrap: "break-word",
                    }}
                  >
                    <h2 style={{ marginBottom: 0, marginTop: 0 }}>
                      {category.name}
                    </h2>
                    <div
                      style={{
                        marginTop: 8,
                        color: "var(--category-secondary-text-color)",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                      }}
                    >
                      {category.description}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "var(--product-card-background-color)",
                        borderRadius: 100,
                        height: 30,
                        width: 30,
                      }}
                    >
                      <TooltipMenu
                        itemsMenu={categorySettings(
                          category._id,
                          categoryIndex
                        )}
                        height={20}
                      />
                    </div>
                  </div>
                </div>
                {category.plats
                  .sort((a, b) => a.order - b.order)
                  .map((product, platIndex) => (
                    <ProductCard
                      key={product._id}
                      name={product.name}
                      description={product.description}
                      price={product.price}
                      image={
                        !product.image.custom
                          ? product.image.value !== undefined &&
                            product.image.value !== "none"
                            ? imagesList.find(
                                (img) => img.name === product.image.value
                              ).image
                            : null
                          : null
                      }
                      customImage={
                        product.image.custom
                          ? product.image.value !== undefined
                            ? product.image.value
                            : null
                          : null
                      }
                      first={platIndex === 0}
                      settings={platSettings(
                        product._id,
                        platIndex,
                        category.plats
                      )}
                      onClick={() =>
                        handleOpenEditProduct(category.plats[platIndex])
                      }
                    />
                  ))}
                <ProductListFooter
                  alone={category.plats.length === 0}
                  categoryId={category._id}
                  menuId={menuId}
                />
              </div>
            ))}
        <div style={{ marginBottom: 30 }}>
          <ProductListFooter
            showCreateDish={false}
            alone={true}
            menuId={menuId}
          />
        </div>
      </Container>
      {openEditProduct && (
        <EditProductModal
          open={openEditProduct}
          handleClose={handleCloseEditProduct}
          categoryId={productToEdit.categorie}
          name={productToEdit.name}
          description={productToEdit.description}
          price={productToEdit.price}
          image={
            !productToEdit.image.custom
              ? productToEdit.image.value !== undefined &&
                productToEdit.image.value !== "none"
                ? productToEdit.image.value
                : null
              : null
          }
          customImage={
            productToEdit.image.custom
              ? productToEdit.image.value !== undefined
                ? productToEdit.image.value
                : null
              : null
          }
          id={productToEdit._id}
        />
      )}
      {openEditCategorie && (
        <EditCategorieModal
          open={openEditCategorie}
          handleClose={handleCloseEditCategorie}
          name={categorieToEdit.name}
          description={categorieToEdit.description}
          id={categorieToEdit._id}
        />
      )}

      {openEditMenu && (
        <EditMenuModal
          open={openEditMenu}
          handleClose={handleCloseEditMenu}
          id={menuId}
          name={menuInformation?.name ?? ""}
          description={menuInformation?.description ?? ""}
          icon={menuInformation?.icon ?? "fork"}
        />
      )}
    </>
  );
}

export default ProductsList;

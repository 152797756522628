import * as React from "react";
import Container from "@mui/material/Container";
import TextField from "./TextField";

export default function AddItemInputContainer({
  name,
  description,
  placeholder,
  titleFontSize,
  subTitleFontSize,
  type,
  rows,
  value,
  setter,
  price,
  required = false,
  notTextField = false,
  startAdornment,
  removeHorizontalSpace = false,
  style,
  onClickTextField,
  inputValueProps,
  rightName,
  heightProps,
  nameProps,
  autoComplete,
  fontSize,
  descriptionColor,
  disabled = false,
  children,
}) {
  return (
    <Container
      style={{
        margin: removeHorizontalSpace ? 0 : "",
        padding: removeHorizontalSpace ? 0 : "",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            width: "auto",
          }}
        >
          <div
            style={{
              fontSize: titleFontSize ?? 17,
              fontWeight: "500",
              color: "var(--primary-text-color)",
              textAlign: "start",
              marginBottom: 6,
            }}
          >
            {name}
          </div>
          {description && (
            <div
              style={{
                fontSize: subTitleFontSize ?? 15,
                fontWeight: "400",
                color: descriptionColor
                  ? descriptionColor
                  : "var(--secondary-text-color)",
                textAlign: "start",
                marginBottom: 5,
                marginTop: -4,
              }}
            >
              {description}
            </div>
          )}
        </div>
        {rightName && rightName}
      </div>
      {!notTextField && (
        <div style={{ width: "100%" }} className={"textfiel-add"}>
          <TextField
            fontSize={fontSize}
            name={nameProps}
            autoComplete={autoComplete}
            onClick={onClickTextField}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            rows={rows}
            setter={setter}
            price={price}
            value={value}
            required={required}
            startAdornment={startAdornment}
            inputValueProps={inputValueProps}
            heightProps={heightProps}
          />
        </div>
      )}
      {notTextField && children}
    </Container>
  );
}

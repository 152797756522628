import * as React from "react"
const PastaIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 128 128"
    fill={color}
  >
    <path d="M1 1.5C-1.1 4 .3 6.4 4.3 7.4 7.8 8.3 8 8.6 8 12.2c0 3.5-.2 3.8-2.8 3.8C2 16 0 17.6 0 20.2c0 2 2.8 3.8 5.9 3.8 1.8 0 2.1.6 2.1 4 0 3.7-.2 4-2.8 4C2 32 0 33.6 0 36.2c0 2 2.9 3.8 6 3.8 2.1 0 2.1.2 1.7 23.2-.3 19.7-.7 24.4-2.4 30.2-1.1 3.8-2.7 7.3-3.6 7.8-3.3 1.8-1.3 6.8 2.7 6.8 1.2 0 5.9 3.9 11.6 9.4 5.3 5.2 10.7 9.7 12 10.1 1.4.3 18.6.5 38.2.3l35.8-.3 9.8-9.8c5.9-5.9 10.5-9.7 11.8-9.7 4.3 0 6-5.1 2.4-7-1.5-.8-2-2.1-2-5.3 0-5.4-2.3-14.5-5.1-19.9-3.3-6.4-12.4-15.4-19-18.7-8.4-4.2-18.2-5.8-26.5-4.2-8.5 1.5-16.2 5-21.3 9.6L48 66.2V39.1l2.8-1.5c1.5-.8 3.6-2.3 4.6-3.5 1.9-2 2.7-2.1 31.5-2.2 32.8 0 35.2-.4 38.8-5.8 2.7-4 2.9-7.1.7-11.6-2.9-6.2-4.7-6.5-38.6-6.5H57.3l-2.4-2.6C50.4.4 47.8 0 24.2 0 6.2 0 2 .3 1 1.5zm23 37.7v31.3l-4 2.2-4 2.1V8h8v31.2zm16-1.3v29.9l-4 .4-4 .4V8h8v29.9zM86.8 16l32.7.5.3 2.9c.2 1.6-.2 3.2-1 3.7-.7.5-15.5.9-32.8.9H54.6l-3.3 3.2-3.3 3.2V9.6l3.1 3 3 2.9 32.7.5zm8.3 47.2c12.2 5.6 19.9 16.5 20.7 29.5l.5 7.3h-8l-.6-6c-1.6-15.1-12.8-25.4-27.6-25.4-15 .1-26.2 10.6-27.8 26l-.6 5.4H44v-5.8c.1-10.2 6.3-21.6 15-27.7 10.3-7.1 25.1-8.5 36.1-3.3zM39.3 79.9c-1.3 3.4-2.1 4.1-5.4 4.6-6.1 1.1-10.1 4.4-12.4 10.3-1.9 5-2.2 5.2-5.7 5.2s-3.8-.2-3.8-2.9C12 86.4 23.6 76 35.7 76h5.1l-1.5 3.9zm48.5-2.4c7.4 3.2 12.2 10.2 12.2 18v4.5h-4c-3.8 0-4-.1-4-3.3 0-4.5-3.4-9.5-7.6-11.3-7.3-3.1-15 1.9-16.1 10.4-.6 4.1-.6 4.2-4.4 4.2-3.9 0-3.9 0-3.9-4 0-4.8 2.2-10.5 5.3-13.9 5.2-5.6 15.1-7.6 22.5-4.6zM36 96v4h-4c-3.3 0-4-.3-4-1.9 0-2.1 4.4-6.1 6.6-6.1 1 0 1.4 1.3 1.4 4zm46.8-2.8c.7.7 1.2 2.5 1.2 4 0 2.6-.3 2.8-4 2.8s-4-.2-4-2.8c0-3.1 1.6-5.2 4-5.2.9 0 2.1.5 2.8 1.2zm22.4 21-6.2 6.3H29l-6.2-6.3-6.3-6.2h95l-6.3 6.2z" />
  </svg>
)
export default PastaIcon

import * as React from "react";

const SandwichIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path
        d="M1065 4795 c-184 -28 -362 -99 -521 -205 -101 -68 -256 -223 -324
-324 -114 -170 -185 -361 -211 -562 -21 -169 -1 -285 67 -391 l34 -53 -34 -53
c-54 -84 -70 -140 -70 -247 0 -107 16 -163 70 -247 l34 -53 -34 -53 c-54 -84
-70 -140 -70 -247 0 -107 16 -163 70 -247 l34 -53 -34 -53 c-54 -84 -70 -140
-70 -247 0 -107 16 -163 70 -247 l34 -53 -34 -53 c-59 -91 -70 -138 -70 -272
1 -100 6 -135 28 -208 87 -289 290 -492 583 -584 l88 -28 1855 0 1855 0 88 28
c293 92 496 295 583 584 22 73 27 108 28 208 0 134 -11 181 -70 272 l-34 53
34 53 c54 84 70 140 70 247 0 107 -16 163 -70 247 l-34 53 34 53 c54 84 70
140 70 247 0 107 -16 163 -70 247 l-34 53 34 53 c54 84 70 140 70 247 0 107
-16 163 -70 247 l-34 53 34 53 c68 106 88 222 67 391 -44 343 -223 654 -496
858 -95 71 -291 169 -395 198 -183 50 -165 50 -1670 49 -1076 -1 -1424 -4
-1485 -14z m645 -475 c0 -171 2 -195 20 -230 23 -45 80 -80 130 -80 50 0 107
35 130 80 18 35 20 59 20 230 l0 190 200 0 200 0 0 -190 c0 -171 2 -195 20
-230 23 -45 80 -80 130 -80 50 0 107 35 130 80 18 35 20 59 20 230 l0 190 200
0 200 0 0 -190 c0 -171 2 -195 20 -230 23 -45 80 -80 130 -80 50 0 107 35 130
80 18 35 20 59 20 230 l0 190 200 0 200 0 0 -190 c0 -221 9 -254 80 -290 50
-25 90 -25 140 0 70 36 80 70 80 275 0 96 2 175 3 175 15 0 124 -43 177 -70
305 -154 504 -451 527 -786 5 -84 4 -95 -18 -134 -14 -25 -38 -50 -61 -61 -36
-18 -96 -19 -2179 -19 l-2142 0 -42 24 c-62 37 -80 83 -72 193 24 334 223 630
527 783 53 27 162 70 177 70 1 0 3 -79 3 -175 0 -156 2 -180 20 -215 23 -45
80 -80 130 -80 50 0 107 35 130 80 18 35 20 59 20 230 l0 190 200 0 200 0 0
-190z m3030 -1230 c45 -23 80 -80 80 -131 0 -46 -39 -108 -82 -130 -36 -18
-96 -19 -2179 -19 l-2141 0 -40 22 c-101 57 -102 194 -2 254 l39 24 2143 0
c2128 0 2143 0 2182 -20z m-3812 -725 c72 -79 132 -147 132 -150 0 -3 -145 -5
-321 -5 -314 0 -322 0 -361 22 -101 57 -102 194 -2 254 37 23 47 24 229 24
l190 0 133 -145z m1242 -47 c96 -105 175 -197 175 -203 0 -14 -373 -425 -385
-425 -8 0 -750 806 -750 815 0 3 177 5 393 5 l392 0 175 -192z m1740 187 c0
-9 -742 -815 -750 -815 -8 0 -750 806 -750 815 0 3 338 5 750 5 413 0 750 -2
750 -5z m830 -15 c45 -23 80 -80 80 -131 0 -46 -39 -108 -82 -130 -34 -17 -63
-19 -358 -19 -176 0 -320 2 -320 5 0 3 60 71 133 150 l132 145 188 0 c169 0
192 -2 227 -20z m-3262 -725 c72 -79 132 -147 132 -150 0 -3 -268 -5 -596 -5
l-596 0 -40 22 c-101 57 -102 194 -2 254 l39 24 465 0 465 0 133 -145z m3262
125 c45 -23 80 -80 80 -131 0 -46 -39 -108 -82 -130 -35 -18 -68 -19 -633 -19
-327 0 -595 2 -595 5 0 3 60 71 133 150 l132 145 463 0 c444 0 464 -1 502 -20z
m-2052 -137 c67 -72 122 -134 122 -137 0 -3 -112 -6 -250 -6 -137 0 -250 3
-250 6 0 9 244 273 251 271 3 -1 61 -61 127 -134z m2052 -463 c60 -31 84 -85
77 -175 -19 -219 -165 -404 -375 -477 l-67 -23 -1815 0 -1815 0 -68 23 c-207
72 -353 257 -374 474 -8 88 13 138 71 173 l41 25 2143 0 c2128 0 2143 0 2182
-20z"
      />
    </g>
  </svg>
);
export default SandwichIcon;

import * as React from "react"
const WatermelonIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 128 128"
    fill={color}
  >
    <path d="M50.2 49.2C23.1 76.3.7 99.5.4 100.8c-.8 3 1.5 6.2 8.3 11.5 7.2 5.5 15.7 9.8 25.3 12.9 6.3 1.9 9.5 2.3 20.5 2.2 11.3-.1 14.1-.4 21-2.7 24.2-8.1 41.1-25 49.2-49.2 2.3-6.9 2.6-9.7 2.7-21 .1-11-.3-14.2-2.2-20.5-3.1-9.6-7.4-18.1-12.9-25.2-4.9-6.4-7.8-8.8-10.7-8.8-1.4 0-19.1 17-51.4 49.2zM108.9 26c13.4 25.9 6.5 57.9-16.3 76.2-11.6 9.3-23.3 13.3-38.6 13.2-14.3-.1-26-3.7-35.8-11.2l-3.3-2.5 4.7-4.8 4.6-4.7 4.6 3.4c9.1 7.1 17.1 9.7 29.2 9.7 21.1.1 38.7-12.9 45.6-33.5 2.2-6.9 2.3-20.3 0-28C102 38.3 95.4 27 93 25.5c-.8-.5.4-2.3 3.7-5.7l4.8-4.9 2.3 2.8c1.3 1.5 3.6 5.3 5.1 8.3zM89.3 40.8C96.7 54.5 94.2 71.9 83 83c-12.7 12.8-32.4 14.1-46.9 3.2l-3.3-2.5 25.3-25.3c28.3-28.3 26.1-27 31.2-17.6z" />
    <path d="M74.5 50.4c-2.8 2.8-3 3.8-1.4 6.7 1.5 2.9 4.9 3.9 8 2.3 3.5-1.8 4.4-5.2 2-8.4-2.5-3.4-5.5-3.7-8.6-.6zM69.2 67.6c-2.6 1.8-3 6.5-.6 8.8 2.3 2.4 7 2 8.8-.6 2.1-3 2-5.4-.4-7.8-2.4-2.4-4.8-2.5-7.8-.4zM52.3 73.1c-2.3.8-4.3 3.8-4.3 6.3 0 2.2 3.6 5.6 6 5.6 2.9 0 6-3.5 6-6.9 0-4.1-3.6-6.5-7.7-5z" />
  </svg>
)
export default WatermelonIcon

import * as React from "react";
import NewCampaign from "./NewCampaign";
import CustomModal from "../imported/Modal/Modal";

export default function NewCampaignModal({ open, handleClose }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <NewCampaign closeModal={handleClose} />
    </CustomModal>
  );
}

import * as React from "react";
const TeasIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M49.6 77.3c-2.5 1.4-4.8 3.9-6.3 6.6L41 88.3v88.1c0 50.7.5 92.1 1 97.5 5.1 48.4 29.2 92.8 66.8 123.2l7.3 5.9H65.3c-56.5 0-55.7 0-61.6 6.9-6.5 7.7-3.8 20.5 5.4 25.1 3.8 2 6.9 2 213.5 2 209.6 0 209.6 0 213.3-2.1 11.6-6.5 11.5-23.2-.2-29.7-2.9-1.5-8.4-1.7-54.9-2.2l-51.7-.5 7-5.5c13.7-10.7 32.3-32.1 42.3-48.7 12.1-20.3 19.9-43.4 24.1-71.4l.5-3.6 17.3-.6c11.3-.3 19.2-1 23.2-2.1 34-9.3 57.5-33 66.1-66.6 2.7-10.6 2.5-31.5-.4-42.2-7.2-26.7-26.1-48.8-50.7-59.6-12.4-5.4-17.6-6.4-36.7-6.9l-17.6-.5-.7-4.3c-.7-5.3-3.9-10.2-8.4-13.2l-3.4-2.3H53.5l-3.9 2.3zM370 180.7c-.1 73.2-.6 90.7-3.5 104.8-3.8 18.5-13 39.7-24.3 55.5-8.1 11.4-23 26.4-34 34.3-16.7 12-39.2 21.6-59.5 25.5-13.9 2.6-41.8 2.3-55.4-.6-45.2-9.8-82.3-38.3-102.3-78.7-9.1-18.6-13.2-33-15-53-.5-5.5-.9-43.6-1-84.8V109h295v71.7zm66.3-50.2c14.5 3.8 29.2 15.6 35.5 28.5 3.5 7.1 6.2 17.9 6.2 25 0 19.4-12.6 39.7-29.9 48.5-9.6 4.8-17.9 6.5-32.3 6.5H404V129h13.3c8.5 0 15.3.5 19 1.5z" />
  </svg>
);
export default TeasIcon;

import * as React from "react"
const SteakIcon = ({ color, width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    fill={color}
  >
    <path d="M309 1.5c-64.7 7.1-122.8 36.4-174.6 88-70.2 70-120.4 176.2-132.6 280-.7 6-1.2 20.2-1.2 31.5 0 18.9.2 21.3 2.7 30.2 10.2 38 39.2 67 77.3 77.4 8.9 2.5 11.6 2.7 26.4 2.7 14.7 0 17.6-.3 26.5-2.6 11.1-3 25.2-9.4 34.5-15.7 17.2-11.7 32.1-30.7 39.5-50.3 8.7-23.2 22.6-40.6 43.9-54.9 26.4-17.6 55.1-26.8 99.6-31.8 26.9-3 41.8-7.3 63-18.2 48.8-25 84.9-73.8 95.2-128.6 2.6-13.9 3.1-43.4.9-56.7-7.7-47.6-32.4-88.9-69.6-116.6C402.1 7.3 358.8-4 309 1.5zm53.1 31.4c27.5 5.5 52.9 18.7 73.2 38.1 23 21.8 37.1 47.3 43.8 79 3.2 15.2 3.2 41.1 0 56-10 46.6-39.7 85.8-80.6 106.2-16.2 8.1-29.1 11.8-49 13.8-59.2 6.1-104.4 23.9-135.2 53.3-15.7 15.1-26.1 30.6-34.3 51.2-11.9 30.1-36.9 48.9-68 51.1-33.6 2.4-66.4-19.3-77.5-51.3-3.8-10.8-4.8-20.1-4.1-36.3 1.1-26.3 4.3-47.6 11.3-75.5 20.4-81.5 62.8-157.9 116.5-210 48.4-47.1 98.5-71.4 160.3-77.9 8.7-.9 34.2.4 43.6 2.3z" />
    <path d="M319.5 50.6c-55.3 5.8-102.3 28.5-146.1 70.6-68.3 65.8-119 178.3-123 273.3C49.4 417.9 54 432 67 445c21.9 21.8 56.3 22.4 79.1 1.2 7.1-6.5 11-12.5 15.5-23.8 10.7-26.6 25.4-47.2 45.9-64.3 34.4-28.6 79.2-45.6 136.4-51.6 16.4-1.7 23.8-3.3 33.5-6.9 41.4-15.6 72.8-53 82.3-97.9 2.1-10.1 2.4-33.9.4-44.7-3.1-17.5-11.1-36.9-21.4-52-6.7-9.7-22.1-25-31.8-31.7-14.9-10.2-32.2-17.5-49.1-20.8-8.2-1.6-30.6-2.7-38.3-1.9zM345.9 81c26.3 3.4 52 18.7 67.7 40.5 8.8 12.3 15.5 29.9 17.5 45.7 4.8 39.4-17.6 80.7-54.1 99.7-10.9 5.7-19.3 7.8-39.2 10.1-101.8 11.3-173.4 57.7-202.9 131.4-6.2 15.4-12.8 21.6-24.6 23.2-8.2 1-15.5-1.6-21.8-8-8.4-8.4-9.6-14.4-7.4-39.1 7.3-84.1 51.6-180.3 109.5-238 21-20.8 40.6-35.2 63.9-46.5 16.5-8.1 26.9-11.9 42.6-15.4 18.9-4.2 34.4-5.4 48.8-3.6z" />
    <path d="M323.4 117c-22.8 4.1-41.9 20.7-49.1 42.5-3.8 11.5-3.8 26.6 0 38 11 33.4 47.2 51.2 80.3 39.6 17.1-6.1 31.8-21.3 38-39.4 2.3-7 2.7-9.8 2.8-19.2 0-9.7-.4-12-3-19.5-3.8-10.7-8.6-18.1-17.2-26.2-7.1-6.6-16.6-12-25.6-14.4-6.8-1.8-19.9-2.5-26.2-1.4zm24.3 33c11.1 5.5 17.7 16 17.7 28.6.1 17.6-14.2 31.8-31.9 31.8-12.6 0-23-6.6-28.7-18.2-9-18.2.9-39.2 21.1-44.9 6.5-1.8 14.5-.8 21.8 2.7z" />
  </svg>
)
export default SteakIcon

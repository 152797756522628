import React from "react";
import Container from "@mui/material/Container";
import { MenusContext } from "../Context/MenusContext";
import EditMenuModal from "../EditMenu/EditMenuModal";
import TopMenuList from "./TopMenusList";
import { useNavigate, useParams } from "react-router-dom";
import NoMenuCard from "./NoMenuCard";
import CustomListButton from "../common/CustomListButton";
import ProductCard from "../common/ProductCard";
import SuccessModal from "../common/SuccessModal";
import Divider from "../common/Divider";

function MenusList() {
  const { menus, deleteMenu, moveMenu, fetchMenus, code } =
    React.useContext(MenusContext);
  const { campaignId, campaignName } = useParams();
  const navigate = useNavigate();

  const [openEditMenu, setOpenEditMenu] = React.useState(false);
  const [menuToEdit, setMenuToEdit] = React.useState(null);
  const [showNeedMoreModal, setShowNeedMoreModal] = React.useState(false);

  const handleMenuClick = (menuId) => {
    navigate(`/campaign/${campaignId}/${campaignName}/${menuId}`);
    console.log("click");
  };

  const handleFinish = () => {
    if (menus.length < 1) {
      setShowNeedMoreModal(true);
      return;
    } else {
      navigate(`/campaign/${campaignId}/${campaignName}/create`);
    }
  };

  const handleOpenEditMenu = (product) => {
    setMenuToEdit(product);
    setOpenEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    setMenuToEdit(null);
    setOpenEditMenu(false);
  };

  const menuSettings = (articleId, index) => {
    const isFirst = index === 0;
    const isLast = index === menus.length - 1;

    return [
      {
        name: "Editer",
        action: () => handleOpenEditMenu(menus[index]),
      },
      {
        name: "Supprimer",
        action: () => deleteMenu(articleId),
      },
      {
        name: "Déplacer en Haut",
        action: () => moveMenu(articleId, "moveUp"),
        hidden: isFirst,
      },
      {
        name: "Déplacer en Bas",
        action: () => moveMenu(articleId, "moveDown"),
        hidden: isLast,
      },
    ];
  };

  React.useEffect(() => {
    fetchMenus(campaignId);
  }, []);
  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <TopMenuList />
        {menus &&
          menus
            .sort((a, b) => a.order - b.order)
            .map((menu, menuIndex) => (
              <div key={menu._id} style={{ marginBottom: 15 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 2,
                  }}
                >
                  <ProductCard
                    key={menu._id}
                    propsIcon={menu.icon}
                    name={menu.name}
                    description={menu.description}
                    first={true}
                    settings={menuSettings(menu._id, menuIndex)}
                    alone={true}
                    onClick={() => handleMenuClick(menu._id)}
                  />
                </div>
              </div>
            ))}
        {menus &&
          menus > 0 &&
          menus
            .sort((a, b) => a.order - b.order)
            .map((menu, menuIndex) => (
              <div key={menu._id} style={{ marginBottom: 15 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 2,
                  }}
                >
                  <ProductCard
                    key={menu._id}
                    name={menu.name}
                    description={menu.description}
                    first={true}
                    settings={menuSettings(menu._id, menuIndex)}
                    alone={true}
                    onClick={() => handleMenuClick(menu._id)}
                  />
                </div>
              </div>
            ))}
        {(!menus || menus.length) < 1 && <NoMenuCard />}
        {!code.hasCode && (
          <div
            style={{
              margin: "15px 0px 15px 0px",
              width: "100%",
              minWidth: "100%",
            }}
          >
            <CustomListButton
              onClick={() => handleFinish()}
              text={"Créer un Code"}
              primary={true}
              minWidth={"100%"}
            />
          </div>
        )}
        {code.hasCode && (
          <>
            <Divider />
            <div
              style={{
                margin: "15px 0px 15px 0px",
                width: "100%",
                minWidth: "100%",
              }}
            >
              <Container
                sx={{
                  borderRadius: "10px",
                  fontWeight: 500,
                  padding: 2,
                  border:
                    "1px solid var(--create-product-secondary-button-border-color)",
                  backgroundColor:
                    "var(--create-product-secondary-button-color)",
                }}
              >
                Code Promo : {code.code.toUpperCase()}
              </Container>
            </div>
          </>
        )}
      </Container>
      {showNeedMoreModal && (
        <SuccessModal
          title={"Pas assez d'éléments"}
          successMessage={
            "Vous devez ajouter au moins un menu et 20 plats pour continuer."
          }
          open={showNeedMoreModal}
          handleClose={() => setShowNeedMoreModal(false)}
        />
      )}
      {openEditMenu && (
        <EditMenuModal
          open={openEditMenu}
          handleClose={handleCloseEditMenu}
          id={menuToEdit._id}
          name={menuToEdit.name}
          description={menuToEdit.description}
          icon={menuToEdit.icon ? menuToEdit.icon : "fork"}
        />
      )}
    </>
  );
}

export default MenusList;

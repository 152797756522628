import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { SettingsContext } from "../../Context/SettingsContext";
import { useTranslation } from "react-i18next";
import UploadFolderButton from "../../common/UploadFolder/UploadFolderButton";
import { bannerImagesList } from "../../bannerImagesList";

function Appearance({campaignId}) {
  const { t } = useTranslation("global");
  const { settings, updateSettings } = React.useContext(SettingsContext);

  const [showRestaurantName, setShowRestaurantName] = React.useState(
    settings?.appearance?.showRestaurantName
  );
  const [theme, setTheme] = React.useState(settings?.appearance?.themeColor);
  const [image, setImage] = React.useState(
    !settings?.appearance?.banner?.custom && settings?.appearance?.banner?.value
      ? bannerImagesList.find(
          (img) => img.name === settings?.appearance?.banner?.value
        )
      : null
  );
  const [customImage, setCustomImage] = React.useState(
    settings?.appearance?.banner?.custom
      ? settings?.appearance?.banner?.value
      : null
  );

  const handleRestaurantNameChange = (event) => {
    setShowRestaurantName(event.target.value);
  };

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 20,
        borderTop: "1px solid var(--border-color)",
      }}
    >
      <div
        style={{
          gap: 15,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddItemInputContainer
          name={t("settingsCoverImageName")}
          description={t("settingsCoverImageNameInformation")}
          titleFontSize={15}
          subTitleFontSize={15}
          notTextField={true}
        >
          <UploadFolderButton
            imageListToUse={bannerImagesList}
            showCategory={false}
            image={image}
            setImage={setImage}
            customImage={
              customImage && customImage.image !== null
                ? customImage
                : { image: customImage }
            }
            setCustomImage={setCustomImage}
          />
        </AddItemInputContainer>
        {/**
        <AddItemInputContainer
          name={t("settingsShowRestaurantName")}
          description={t("settingsShowRestaurantNameInformation")}
          titleFontSize={15}
          subTitleFontSize={15}
          notTextField={true}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={showRestaurantName}
            onChange={handleRestaurantNameChange}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
              width: "100%",
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio size={"15px"} />}
              label={t("textShow")}
              sx={{ ".MuiTypography-root": { fontSize: 15 } }}
            />
            <FormControlLabel
              value={false}
              control={<Radio size={"15px"} />}
              label={t("textDontShow")}
              sx={{ ".MuiTypography-root": { fontSize: 15 } }}
            />
          </RadioGroup>
        </AddItemInputContainer>
        */}
        <AddItemInputContainer
          name={t("textTheme")}
          titleFontSize={15}
          notTextField={true}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={theme}
            onChange={handleThemeChange}
            sx={{ ".Mui-checked": { width: "auto !important" } }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
              width: "100%",
            }}
          >
            <FormControlLabel
              value="LIGHT_THEME"
              control={<Radio size={"15px"} />}
              label={t("textLightTheme")}
              sx={{ ".MuiTypography-root": { fontSize: 15 } }}
            />
            <FormControlLabel
              value="DARK_THEME"
              control={<Radio size={"15px"} />}
              label={t("textDarkTheme")}
              sx={{ ".MuiTypography-root": { fontSize: 15 } }}
            />
          </RadioGroup>
        </AddItemInputContainer> 
      </div>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={() => {
            console.log("showRestaurantName", showRestaurantName);
            console.log("theme", theme);
            updateSettings({
              appearance: {
                showRestaurantName: showRestaurantName,
                themeColor: theme,
              },
              customImage: customImage !== null ? customImage : null,
              image: image !== null ? image : null,
            }, campaignId);
          }}
        />
      </div>
    </div>
  );
}

export default Appearance;

import * as React from "react";
import CustomModal from "../Modal/Modal";
import EditCategorie from "./EditCategorie";

export default function EditCategorieModal({
  open,
  handleClose,
  id,
  name,
  description,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <EditCategorie
        closeModal={handleClose}
        actualName={name}
        actualDescription={description}
        id={id}
      />
    </CustomModal>
  );
}

import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import {
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { SettingsContext } from "../../Context/SettingsContext";
import { useTranslation } from "react-i18next";

function Review({campaignId}) {
  const { t } = useTranslation("global");
  const { settings, updateSettings } = React.useContext(SettingsContext);
  const [display, setDisplay] = React.useState(settings?.review?.display);
  const [link, setLink] = React.useState(settings?.review?.link);

  const handleDisplayChange = (event) => {
    setDisplay(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 20,
        borderTop: "1px solid var(--border-color)",
      }}
    >
      <div
        style={{
          gap: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddItemInputContainer
          name={t("settingsReviewDisplay")}
          titleFontSize={15}
          subTitleFontSize={15}
          notTextField={true}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={display}
            onChange={handleDisplayChange}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
              width: "100%",
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio size={"15px"} />}
              label={t("textShow")}
              sx={{ ".MuiTypography-root": { fontSize: 15 } }}
            />
            <FormControlLabel
              value={false}
              control={<Radio size={"15px"} />}
              label={t("textDontShow")}
              sx={{ ".MuiTypography-root": { fontSize: 15 } }}
            />
          </RadioGroup>
        </AddItemInputContainer>
        <AddItemInputContainer
          setter={setLink}
          value={link}
          name={t("settingsReviewLink")}
          description={t("settingsReviewLinkInformation")}
          titleFontSize={15}
        />
      </div>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={() => {
            updateSettings({
              review: {
                link,
                display,
              },
            }, campaignId);
          }}
        />
      </div>
    </div>
  );
}

export default Review;

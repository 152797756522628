import React, { useState } from "react";
import axios from "axios";
import ProductCard from "./imported/common/ProductCard";
import { Container } from "@mui/material";
import Divider from "./imported/common/Divider";
import Loading from "./imported/Loading/Loading";
import { API_URL } from "./imported/consts";

function PromoCodeStats() {
  const [code, setCode] = useState("");
  const [stats, setStats] = useState({
    totalMonthlySubscriptions: 0,
    totalYearlySubscriptions: 0,
  });
  const [codes, setCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllUserCampaigns = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}promo-codes`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setCodes(response.data.promoCodes);
      setStats(response.data.stats);
    } catch (error) {
      alert(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAllUserCampaigns();
  }, []);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <h1 style={{ marginBottom: 0 }}>Statistiques</h1>
      <div style={{ width: "100%", height: 1, margin: "10px 0px 15px 0px" }}>
        <Divider />
      </div>
      <h2>Mois en cours</h2>
      <h2>Mois prochain</h2>
      {stats.totalMonthlySubscriptions * 10} €
      <h1 style={{ marginBottom: 0 }}>Codes</h1>
      <div style={{ width: "100%", height: 1, margin: "10px 0px 15px 0px" }}>
        <Divider />
      </div>
      {isLoading && (
        <div
          style={{
            zoom: 0.8,
          }}
        >
          <Loading fullScreen={false} />
        </div>
      )}
      {codes.map((code, index) => (
        <ProductCard
          key={code._id}
          name={code.code}
          description={`Utilisé ${code.timesUsed} fois`}
          image={null}
          customImage={null}
          first={index === 0}
          alone={codes.length === 1}
          settings={[]}
          onClick={() => {}}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: 10,
              width: "100px",
              color: "green",
            }}
          >
            Actif : {code.activeSubscriptions}
          </div>
        </ProductCard>
      ))}
    </Container>
  );
}

export default PromoCodeStats;
